import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IconButton, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import {
  formatAlarm, formatAltitude, formatBoolean, formatCoordinate, formatCourse, formatDistance, formatNumber, formatNumericHours, formatPercentage, formatSpeed, formatTime, getLastStopColor, getLastUpdateColor, formatVoltage,
} from '../util/formatter';
import { useAttributePreference, usePreference } from '../util/preferences';
import { useTranslation } from './LocalizationProvider';
import AddressValue from './AddressValue';
import { useGlobalStyles } from '../util/classes';

const PositionValue = ({ position, property, attribute }) => {
  const classes = useGlobalStyles();
  const t = useTranslation();

  const device = useSelector((state) => state.devices.items[position.deviceId]);

  const key = property || attribute;
  const value = property ? position[property] : position.attributes[attribute];

  const distanceUnit = useAttributePreference('distanceUnit');
  const altitudeUnit = useAttributePreference('altitudeUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const coordinateFormat = usePreference('coordinateFormat');
  const hours12 = usePreference('twelveHourFormat');

  const formatValue = () => {
    switch (key) {
      case 'fixTime':
      case 'deviceTime':
        return formatTime(value, 'minutes', hours12);
      case 'serverTime':
        if (value) {
          return (<div className={classes[getLastUpdateColor(value)]}>{moment(value).fromNow()}</div>);
        }
        return '';
      case 'latitude':
        return formatCoordinate('latitude', value, coordinateFormat);
      case 'longitude':
        return formatCoordinate('longitude', value, coordinateFormat);
      case 'speed':
        return formatSpeed(value, speedUnit, t);
      case 'course':
        return formatCourse(value);
      case 'altitude':
        return formatAltitude(value, altitudeUnit, t);
      case 'batteryLevel':
        return formatPercentage(value, t);
      case 'alarm':
        return formatAlarm(value, t);
      case 'odometer':
      case 'distance':
      case 'totalDistance':
        return formatDistance(value, distanceUnit, t);
      case 'hours':
        return formatNumericHours(value, t);
      default:
        if (typeof value === 'number') {
          return formatNumber(value);
        } if (typeof value === 'boolean') {
          return formatBoolean(value, t);
        }
        return value || '';
    }
  };

  switch (key) {
    case 'image':
    case 'video':
    case 'audio':
      return (<Link href={`/api/media/${device.uniqueId}/${value}`} target="_blank">{value}</Link>);
    case 'totalDistance':
    case 'hours':
      return formatValue(value);
    case 'address':
      return (<AddressValue latitude={position.latitude} longitude={position.longitude} originalAddress={value} />);
    case 'network':
      if (value) {
        return (<Link component={RouterLink} underline="none" to={`/network/${position.id}`}>{t('sharedInfoTitle')}</Link>);
      }
      return '';
    case 'signal':
      return (
        <IconButton size="small">
          {value === 0 ? (
            <SignalCellular0BarIcon fontSize="small" className={classes.negative} />
          ) : value === 1 ? (
            <SignalCellular1BarIcon fontSize="small" className={classes.medium} />
          ) : value === 2 ? (
            <SignalCellular2BarIcon fontSize="small" className={classes.medium} />
          ) : value === 3 ? (
            <SignalCellular3BarIcon fontSize="small" className={classes.positive} />
          ) : (
            <SignalCellular4BarIcon fontSize="small" className={classes.positive} />
          )}
        </IconButton>
      );
    case 'lastStop':
      if (value) {
        return (<div className={classes[getLastStopColor(value)]}>{moment(value).fromNow()}</div>);
      }
      return '';
    case 'power':
    case 'battery':
      return formatVoltage(value);
    default:
      return formatValue(value);
  }
};

export default PositionValue;
