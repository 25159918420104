import React, { useState } from 'react';
import ReportFilter from './components/ReportFilter';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { handleErrorResponse } from '../common/util/utils';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapMarkers from '../map/MapMarkers';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';
import ReportTable from './components/ReportTable';
import useFormatOptions from './common/useFormatOptions';
import { formatValue, handleReportSubmit } from './common/reportUtils';

const columnsArray = [
  ['startTime', 'reportStartTime'],
  ['startAddress', 'reportStartAddress'],
  ['endTime', 'reportEndTime'],
  ['endAddress', 'reportEndAddress'],
  ['distance', 'sharedDistance'],
  ['averageSpeed', 'reportAverageSpeed'],
  ['maxSpeed', 'reportMaximumSpeed'],
  ['duration', 'reportDuration'],
  ['startOdometer', 'reportStartOdometer'],
  ['endOdometer', 'reportEndOdometer'],
];
const columnsMap = new Map(columnsArray);

const TripReportPage = () => {
  const classes = useReportStyles();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [route, setRoute] = useState(null);

  const createMarkers = () => ([
    {
      latitude: selectedItem.startLat,
      longitude: selectedItem.startLon,
      color: 'negative',
    },
    {
      latitude: selectedItem.endLat,
      longitude: selectedItem.endLon,
      color: 'positive',
    },
  ]);

  useEffectAsync(async () => {
    if (selectedItem) {
      const query = new URLSearchParams({
        deviceId: selectedItem.deviceId,
        from: selectedItem.startTime,
        to: selectedItem.endTime,
      });
      const response = await fetch(`/api/reports/route?${query.toString()}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        setRoute(await response.json());
      } else {
        await handleErrorResponse(response);
      }
    } else {
      setRoute(null);
    }
  }, [selectedItem]);

  const handleSubmit = useCatch(async ({ deviceId, from, to, type }) => {
    await handleReportSubmit(deviceId, from, to, type, '/api/reports/trips', setItems, setLoading);
  });

  // Use the custom hook to get the formatting options
  const formatOptions = useFormatOptions();
  const handleFormatValue = (item, key) => formatValue(item, key, formatOptions);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportTrips']}>
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              {route && (
                <>
                  <MapRoutePath positions={route} />
                  <MapMarkers markers={createMarkers()} />
                  <MapCamera positions={route} />
                </>
              )}
            </MapView>
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit}>
              {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
            </ReportFilter>
          </div>
          <ReportTable
            columnsMap={columnsMap}
            items={items}
            loading={loading}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            formatValue={handleFormatValue}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default TripReportPage;
