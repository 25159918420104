// src/utils/useFormatOptions.js
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useFormatOptions = () => {
  const hours12 = usePreference('twelveHourFormat');
  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const t = useTranslation();

  // Return all options needed for formatting
  return {
    hours12,
    distanceUnit,
    volumeUnit,
    speedUnit,
    t,
  };
};

export default useFormatOptions;
