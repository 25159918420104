import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import StatusRow from './StatusRow';
import { MapIconButton } from './MapElement';
import { handleErrorResponse } from '../util/utils';

const LotInfoDialog = ({ deviceId, open, handleClose }) => {
  const t = useTranslation();

  const [lot, setLot] = useState();

  useEffect(() => {
    const fetchLotInfo = async () => {
      const response = await fetch(`/api/devices/${deviceId}/lot`);
      if (!response.ok) {
        await handleErrorResponse(response);
      }
      const data = await response.json();
      setLot(data);
    };

    fetchLotInfo();
  }, [deviceId]);

  const getGoogleBusinessUrl = (name, city, state) => {
    const encode = (str) => encodeURIComponent(str).replace(/%20/g, '+');
    const query = `${encode(name)},+${encode(city)},+${encode(state)}`;
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  };

  return (
    <DialogView
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedImpoundLot')}
      cancelTitle={t('sharedClose')}
      content={lot && (
        <>
          <Table size="small">
            <TableBody>
              <StatusRow
                key="lotName"
                name={t('sharedName')}
                content={`${lot.name}`}
                bold
              />
              {lot.phone && (
                <StatusRow
                  key="lotPhone"
                  name={t('sharedPhone')}
                  content={lot.phone}
                />
              )}
              <StatusRow
                key="lotAddress"
                name={t('sharedAddress')}
                content={lot.address}
              />
              <StatusRow
                key="lotCity"
                name={t('sharedCity')}
                content={lot.city}
              />
              <StatusRow
                key="lotState"
                name={t('sharedState')}
                content={lot.state}
              />
              <StatusRow
                key="lotZip"
                name={t('sharedZip')}
                content={lot.zip}
              />
            </TableBody>
          </Table>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="column" alignItems="center" mx={1}>
              <IconButton
                color="secondary"
                onClick={() => window.open(getGoogleBusinessUrl(lot.name, lot.city, lot.state), '_blank')}
              >
                <CarCrashIcon />
              </IconButton>
              <Typography variant="body2" color="textSecondary">
                {t('linkGoogleListing')}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" mx={1}>
              <MapIconButton longitude={lot.longitude} latitude={lot.latitude} type="street" color="secondary" />
              <Typography variant="body2" color="textSecondary">
                {t('linkStreetView')}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" mx={1}>
              <MapIconButton longitude={lot.longitude} latitude={lot.latitude} type="google" color="secondary" />
              <Typography variant="body2" color="textSecondary">
                {t('linkGoogleMaps')}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" mx={1}>
              <MapIconButton longitude={lot.longitude} latitude={lot.latitude} type="bing" color="secondary" />
              <Typography variant="body2" color="textSecondary">
                {t('linkBingMaps')}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    />
  );
};

export default LotInfoDialog;
