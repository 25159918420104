// src/components/ReportMap.js
import React from 'react';
import useReportStyles from '../common/useReportStyles';
import MapView from '../../map/core/MapView';
import MapGeofence from '../../map/MapGeofence';
import MapPositions from '../../map/MapPositions';
import MapCamera from '../../map/MapCamera';
import MapScale from '../../map/MapScale';

const ReportMap = ({ items, selectedItem, handleMarkerClick }) => {
  const classes = useReportStyles();
  return (
    <div className={classes.containerMap}>
      <MapView>
        <MapGeofence />
        <MapPositions
          positions={selectedItem ? [selectedItem] : items}
          onClick={handleMarkerClick}
          selectedPosition={selectedItem}
          fitBounds
        />
      </MapView>
      <MapScale />
      {selectedItem ? (
        <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} zoom={18} />
      ) : (
        <MapCamera positions={items} />
      )}
    </div>
  );
};

export default ReportMap;
