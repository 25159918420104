import React, { useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneArea } from 'react-mui-dropzone';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import SelectField from '../common/components/SelectField';
import deviceCategories from '../common/util/deviceCategories';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import useDeviceAttributes from '../common/attributes/useDeviceAttributes';
import {
  useAdministrator,
  useDeviceReadonly,
} from '../common/util/permissions';
import SettingsMenu from './components/SettingsMenu';
import useCommonDeviceAttributes from '../common/attributes/useCommonDeviceAttributes';
import useFeatures from '../common/util/useFeatures';
import { useCatch } from '../reactHelper';
import { formatNotificationTitle } from '../common/util/formatter';
import { useAttributePreference } from '../common/util/preferences';
import { distanceFromMeters, distanceToMeters, distanceLongString } from '../common/util/converter';
import { handleErrorResponse } from '../common/util/utils';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  vin: {
    width: '100%',
  },
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const admin = useAdministrator();
  const deviceReadonly = useDeviceReadonly();

  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);

  const features = useFeatures();

  const distanceUnit = useAttributePreference('distanceUnit');

  const [item, setItem] = useState();

  const handleFiles = useCatch(async (files) => {
    if (files.length > 0) {
      const response = await fetch(`/api/devices/${item.id}/image`, {
        method: 'POST',
        body: files[0],
      });
      if (response.ok) {
        setItem({ ...item, attributes: { ...item.attributes, deviceImage: await response.text() } });
      } else {
        await handleErrorResponse(response);
      }
    }
  });

  const handleVINDecode = async (vin) => {
    const response = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    if (response.ok) {
      const data = await response.json();
      data.Results.forEach((entry) => {
        if (entry.Variable === 'Make') {
          item.vehicleMake = entry.Value;
        } else if (entry.Variable === 'Model') {
          item.vehicleModel = entry.Value;
        } else if (entry.Variable === 'Model Year') {
          item.vehicleYear = entry.Value;
        }
      });
      setItem({ ...item });
      // } else {
    //   alert('not ok');
    }
  };

  const validate = () => item && item.name && item.uniqueId;

  return (
    <EditItemView
      endpoint="devices"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['sharedDevice']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.uniqueId || ''}
                onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                label={t('deviceIdentifier')}
                disabled={!admin}
              />
              {admin && (
                <FormControlLabel
                  control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
                  label={t('sharedTerminated')}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedVehicle')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.vin}>
                <TextField
                  className={classes.vin}
                  value={item.vin || ''}
                  onChange={(event) => setItem({ ...item, vin: event.target.value })}
                  label={t('vehicleVIN')}
                  disabled={deviceReadonly}
                  InputProps={item.vin && {
                    endAdornment:
  <InputAdornment position="end">
    {item.id && <Link component={RouterLink} underline="none" onClick={() => { handleVINDecode(item.vin); }}>&#9881;</Link>}
  </InputAdornment>,
                  }}
                />
              </div>
              <TextField
                value={item.vehicleMake || ''}
                onChange={(event) => setItem({ ...item, vehicleMake: event.target.value })}
                label={t('vehicleMake')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.vehicleModel || ''}
                onChange={(event) => setItem({ ...item, vehicleModel: event.target.value })}
                label={t('vehicleModel')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.vehicleYear || ''}
                onChange={(event) => setItem({ ...item, vehicleYear: event.target.value })}
                label={t('vehicleYear')}
                disabled={deviceReadonly}
              />
              <TextField
                type="number"
                value={distanceFromMeters(item.originalDistance, distanceUnit).toFixed(0)}
                onChange={(event) => setItem({ ...item, originalDistance: distanceToMeters(Number(event.target.value), distanceUnit) })}
                label={`${t('sharedOriginal')} ${distanceLongString(distanceUnit, t)}`}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.vehiclePlate || ''}
                onChange={(event) => setItem({ ...item, vehiclePlate: event.target.value })}
                label={t('vehiclePlate')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.vehicleState || ''}
                onChange={(event) => setItem({ ...item, vehicleState: event.target.value })}
                label={t('vehicleState')}
                disabled={deviceReadonly}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedCustomer')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.contact || ''}
                onChange={(event) => setItem({ ...item, contact: event.target.value })}
                label={t('deviceContact')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.phone || ''}
                onChange={(event) => setItem({ ...item, phone: event.target.value })}
                label={t('sharedPhone')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.address || ''}
                onChange={(event) => setItem({ ...item, address: event.target.value })}
                label={t('sharedAddress')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.city || ''}
                onChange={(event) => setItem({ ...item, city: event.target.value })}
                label={t('sharedCity')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.state || ''}
                onChange={(event) => setItem({ ...item, state: event.target.value })}
                label={t('sharedState')}
                disabled={deviceReadonly}
              />
              <TextField
                value={item.zip || ''}
                onChange={(event) => setItem({ ...item, zip: event.target.value })}
                label={t('sharedZip')}
                disabled={deviceReadonly}
              />
            </AccordionDetails>
          </Accordion>
          {admin && item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedExtra')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  value={item.groupId || 0}
                  onChange={(event) => setItem({ ...item, groupId: Number(event.target.value) })}
                  endpoint="/api/groups"
                  label={t('groupParent')}
                  disabled={deviceReadonly}
                />
                <SelectField
                  value={item.category || 'default'}
                  emptyValue={null}
                  onChange={(event) => setItem({ ...item, category: event.target.value })}
                  data={deviceCategories.map((category) => ({
                    id: category,
                    name: t(`category${category.replace(/^\w/, (c) => c.toUpperCase())}`),
                  }))}
                  label={t('deviceCategory')}
                  disabled={deviceReadonly}
                />
                <TextField
                  label={t('userExpirationTime')}
                  type="date"
                  value={(item.expirationTime && moment(item.expirationTime).locale('en').format(moment.HTML5_FMT.DATE)) || '2099-01-01'}
                  onChange={(e) => setItem({ ...item, expirationTime: moment(e.target.value, moment.HTML5_FMT.DATE).format() })}
                  disabled={!admin}
                />
                {admin && (
                  <FormControlLabel
                    control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
                    label={t('sharedDisabled')}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {admin && item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('attributeDeviceImage')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <DropzoneArea
                  dropzoneText={t('sharedDropzoneText')}
                  acceptedFiles={['image/*']}
                  filesLimit={1}
                  onChange={handleFiles}
                  showAlerts={false}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {admin && item.id && (
            <EditAttributesAccordion
              attributes={item.attributes}
              setAttributes={(attributes) => setItem({ ...item, attributes })}
              definitions={{ ...commonDeviceAttributes, ...deviceAttributes }}
            />
          )}
          {admin && item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  endpointAll="/api/geofences"
                  endpointLinked={`/api/geofences?deviceId=${item.id}`}
                  baseId={item.id}
                  keyBase="deviceId"
                  keyLink="geofenceId"
                  label={t('sharedGeofences')}
                />
                <LinkField
                  endpointAll="/api/notifications"
                  endpointLinked={`/api/notifications?deviceId=${item.id}`}
                  baseId={item.id}
                  keyBase="deviceId"
                  keyLink="notificationId"
                  titleGetter={(it) => formatNotificationTitle(t, it)}
                  label={t('sharedNotifications')}
                />
                {!features.disableDrivers && (
                  <LinkField
                    endpointAll="/api/drivers"
                    endpointLinked={`/api/drivers?deviceId=${item.id}`}
                    baseId={item.id}
                    keyBase="deviceId"
                    keyLink="driverId"
                    label={t('sharedDrivers')}
                  />
                )}
                {!features.disableComputedAttributes && (
                  <LinkField
                    endpointAll="/api/attributes/computed"
                    endpointLinked={`/api/attributes/computed?deviceId=${item.id}`}
                    baseId={item.id}
                    keyBase="deviceId"
                    keyLink="attributeId"
                    titleGetter={(it) => it.description}
                    label={t('sharedComputedAttributes')}
                  />
                )}
                <LinkField
                  endpointAll="/api/commands"
                  endpointLinked={`/api/commands?deviceId=${item.id}`}
                  baseId={item.id}
                  keyBase="deviceId"
                  keyLink="commandId"
                  titleGetter={(it) => it.description}
                  label={t('sharedSavedCommands')}
                />
                {!features.disableMaintenance && (
                  <LinkField
                    endpointAll="/api/maintenance"
                    endpointLinked={`/api/maintenance?deviceId=${item.id}`}
                    baseId={item.id}
                    keyBase="deviceId"
                    keyLink="maintenanceId"
                    label={t('sharedMaintenance')}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </EditItemView>
  );
};

export default DevicePage;
