import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  Tooltip,
} from '@mui/material';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from '../../common/components/LocalizationProvider';
import DialogMapView from '../../map/core/DialogMapView';
import DialogMapPositions from '../../map/DialogMapPositions';
import DialogMapCamera from '../../map/DialogMapCamera';
import DialogMapGeofence from '../../map/MapGeofence';
import { handleErrorResponse } from '../../common/util/utils';

const StopLocationsDialog = ({ longitude, latitude, deviceId, from, to, title, disabled }) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const handleClickOpen = async () => {
    if (disabled) return; // Prevent action if disabled

    setLoading(true);
    const query = new URLSearchParams({
      longitude,
      latitude,
      deviceId,
      from,
      to,
    }).toString();
    try {
      const response = await fetch(`/api/reports/locationstops?${query}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        setItems(data);
        setOpen(true); // Open the dialog after data is fetched
      } else {
        await handleErrorResponse(response);
      }
    } catch (error) {
      await handleErrorResponse(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={t('reportStops')}>
        <span>
          <IconButton onClick={handleClickOpen} disabled={disabled}>
            <StopCircleIcon />
          </IconButton>
        </span>
      </Tooltip>
      {loading && <CircularProgress size={24} style={{ marginLeft: 8 }} />}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: '60%',
            maxWidth: '60%',
          },
        }}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: '75vh', width: '100%' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <DialogMapView>
                <DialogMapGeofence />
                <DialogMapPositions positions={items} />
                <DialogMapCamera positions={items} />
              </DialogMapView>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StopLocationsDialog;
