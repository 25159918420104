import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import EditItemView from './components/EditItemView';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import { sessionActions } from '../store';
import SettingsMenu from './components/SettingsMenu';
import { useRestriction } from '../common/util/permissions';
import useQuery from '../common/util/useQuery';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const RecoveryPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const fixedEmail = useRestriction('fixedEmail');

  const currentUser = useSelector((state) => state.session.user);

  const { id } = useParams();
  const [item, setItem] = useState(id === currentUser.id.toString() ? currentUser : null);

  const query = useQuery();
  const [queryHandled, setQueryHandled] = useState(false);
  const attribute = query.get('attribute');

  useEffect(() => {
    if (!queryHandled && item && attribute) {
      if (!item.attributes.hasOwnProperty('attribute')) {
        const updatedAttributes = { ...item.attributes };
        updatedAttributes[attribute] = '';
        setItem({ ...item, attributes: updatedAttributes });
      }
      setQueryHandled(true);
    }
  }, [item, queryHandled, setQueryHandled, attribute]);

  const onItemSaved = (result) => {
    if (result.id === currentUser.id) {
      dispatch(sessionActions.updateUser(result));
    }
  };

  const validate = () => item && item.name && item.email && (item.id || item.password);

  return (
    <EditItemView
      endpoint="users"
      item={item}
      setItem={setItem}
      defaultItem={{ userLimit: 0, deviceLimit: 0, recovery: true, deviceReadonly: true, readonly: true }}
      validate={validate}
      onItemSaved={onItemSaved}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'settingsUser']}
    >
      {item && (
        <Accordion defaultExpanded={!attribute}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedInformation')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.name || ''}
              onChange={(event) => setItem({ ...item, name: event.target.value })}
              label={t('sharedName')}
            />
            <TextField
              value={item.email || ''}
              onChange={(event) => setItem({ ...item, email: event.target.value })}
              label={t('userEmail')}
              disabled={fixedEmail}
            />
            <TextField
              type="password"
              onChange={(event) => setItem({ ...item, password: event.target.value })}
              label={t('userPassword')}
            />
            <TextField
              value={item.phone || ''}
              onChange={(event) => setItem({ ...item, phone: event.target.value })}
              label={t('sharedPhone')}
            />
            {item.id && (
              <LinkField
                endpointAll="/api/devices?all=true"
                endpointLinked={`/api/devices?userId=${item.id}`}
                baseId={item.id}
                keyBase="userId"
                keyLink="deviceId"
                label={t('deviceTitle')}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default RecoveryPage;
