import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import msgpack from 'msgpack-lite';
import { Snackbar } from '@mui/material';
import { devicesActions, sessionActions } from './store';
import { useEffectAsync } from './reactHelper';
import { snackBarDurationLongMs } from './common/util/duration';

import alarm from './resources/alarm.mp3';
import { eventsActions } from './store/events';
import useFeatures from './common/util/useFeatures';
import { useAttributePreference } from './common/util/preferences';
import { fetchMsgPack } from './common/util/utils';

const logoutCode = 4000;

// const createWorker = (workerScript) => {
//   const blob = new Blob([workerScript], { type: 'application/javascript' });
//   return new Worker(URL.createObjectURL(blob));
// };

const SocketController = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => !!state.session.user);
  const socketRef = useRef();
  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const soundEvents = useAttributePreference('soundEvents', '');
  const soundAlarms = useAttributePreference('soundAlarms', 'sos');
  const features = useFeatures();

  // const jsonWorkerScript = `
  //   onmessage = function(e) {
  //     const parsedData = JSON.parse(e.data);
  //     postMessage(parsedData);
  //   };
  // `;
  // const jsonWorker = createWorker(jsonWorkerScript);

  // const parseJSON = (data) => new Promise((resolve) => {
  //   jsonWorker.onmessage = (e) => resolve(e.data);
  //   jsonWorker.postMessage(data);
  // });

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    socket.binaryType = 'arraybuffer';
    socketRef.current = socket;

    socket.onopen = () => {
      dispatch(sessionActions.updateSocket(true));
    };

    socket.onclose = async (event) => {
      dispatch(sessionActions.updateSocket(false));
      if (event.code !== logoutCode) {
        try {
          const devices = await fetchMsgPack('/api/devices');
          dispatch(devicesActions.update(devices));
          const positions = await fetchMsgPack('/api/positions');
          dispatch(sessionActions.updatePositions(positions));
        } catch (error) {
          // ignore errors
        }
        setTimeout(() => connectSocket(), 60000);
      }
    };

    socket.onmessage = async (event) => {
      const data = msgpack.decode(new Uint8Array(event.data)); // Decode the MessagePack data
      if (data.devices) {
        dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        dispatch(sessionActions.updatePositions(data.positions));
      }
      if (data.events) {
        if (!features.disableEvents) {
          dispatch(eventsActions.add(data.events));
        }
        setEvents(data.events);
      }
    };
  };

  useEffectAsync(async () => {
    if (authenticated) {
      const devices = await fetchMsgPack('/api/devices');
      dispatch(devicesActions.refresh(devices));
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close(logoutCode);
        }
      };
    }
    return null;
  }, [authenticated]);

  useEffect(() => {
    setNotifications(events.map((event) => ({
      id: event.id,
      message: event.attributes.message,
      show: true,
    })));
  }, [events]);

  useEffect(() => {
    events.forEach((event) => {
      if (soundEvents.includes(event.type) || (event.type === 'alarm' && soundAlarms.includes(event.attributes.alarm))) {
        new Audio(alarm).play();
      }
    });
  }, [events, soundEvents, soundAlarms]);

  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={notification.show}
          message={notification.message}
          autoHideDuration={snackBarDurationLongMs}
          onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
        />
      ))}
    </>
  );
};

export default connect()(SocketController);
