import { useEffect, useState } from 'react';

export const getCommandIdUseEffect = (name) => {
  const [commands, setCommands] = useState();

  useEffect(() => {
    async function fetchData() {
      fetch('/api/commands')
        .then((response) => response.json())
        .then((data) => {
          setCommands(data);
        });
    }
    fetchData();
  }, []);

  let returnVal = '';
  if (commands) {
    commands.map((command) => {
      if (command.description === name) {
        returnVal = command.id;
      }
      return returnVal;
    });
  }
  return returnVal;
};

export const getCommandId = async (name) => {
  const response = await fetch('/api/commands', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const commands = await response.json();

  let returnVal = '';
  if (commands) {
    commands.map((command) => {
      if (command.description === name) {
        returnVal = command.id;
      }
      return returnVal;
    });
  }
  return returnVal;
};

export const getUserCommandId = async (name, userId) => {
  const response = await fetch(`/api/commands?userId=${userId}`);
  const commands = await response.json();

  let returnVal = null;
  if (commands) {
    commands.map((command) => {
      if (command.description === name) {
        returnVal = command.id;
      }
      return returnVal;
    });
  }
  return returnVal;
};

export const getSignal = (rssi) => {
  if (!rssi) {
    return 0;
  }
  if (rssi === 0) {
    return 1;
  }
  if (rssi === 1) {
    return 2;
  }
  if (rssi < 20) {
    return 3;
  }
  return 4;
};

export const getOperator = (io1, io2) => {
  if (io1 === 310 && io2 === 410) {
    return 'att';
  }
  if (io1 === 310 && io2 === 260) {
    return 'tmobile';
  }
  if (io1 === 311 && io2 === 480) {
    return 'verizon';
  }
  return null;
};
