import React from 'react';
import UsersPage from './UsersPage';

const RecoveriesPage = () => {
  const recovery = 1;
  return (
    <UsersPage recovery={recovery} />
  );
};

export default RecoveriesPage;
