import { useDispatch, useSelector, connect } from 'react-redux';

import {
  geofencesActions, groupsActions, driversActions, maintenancesActions,
} from './store';
import { useEffectAsync } from './reactHelper';
import { fetchAndDecode } from './common/util/utils';

const CachingController = () => {
  const authenticated = useSelector((state) => !!state.session.user);
  const dispatch = useDispatch();

  useEffectAsync(async () => {
    if (authenticated) {
      const geofences = await fetchAndDecode('/api/geofences');
      dispatch(geofencesActions.update(geofences));
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      const groups = await fetchAndDecode('/api/groups');
      dispatch(groupsActions.update(groups));
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      const drivers = await fetchAndDecode('/api/drivers');
      dispatch(driversActions.update(drivers));
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      const maintenance = await fetchAndDecode('/api/maintenance');
      dispatch(maintenancesActions.update(maintenance));
    }
  }, [authenticated]);

  return null;
};

export default connect()(CachingController);
