import React from 'react';
import {
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ImpoundLotIcon from '@mui/icons-material/CarCrash';
import ErrorIcon from '@mui/icons-material/Error';
import {
  formatAlarm, formatPercentage, formatVoltage, formatSignal, formatOperator, formatMotion, formatIgnition, formatHDOP,
} from '../util/formatter';
import { ReactComponent as EngineIcon } from '../../resources/images/data/engine.svg';
import { ReactComponent as TMobileIcon } from '../../resources/images/operator/tmobile.svg';
import { ReactComponent as VerizonIcon } from '../../resources/images/operator/verizon.svg';
import { ReactComponent as ATTIcon } from '../../resources/images/operator/att.svg';
import { useGlobalStyles } from '../util/classes';
import { snackBarDurationLongMs } from '../util/duration';

export const Operator = ({ t, operator }) => {
  // extracted this statement to allow using return! Without return, this tag does not display.
  const formattedOperator = formatOperator(operator);
  return (
    <Tooltip title={`${t('positionOperator')}: ${formattedOperator}`}>
      <IconButton size="small">
        {operator === 'tmobile' ? (
          <TMobileIcon width={20} height={20} />
        ) : operator === 'att' ? (
          <ATTIcon width={20} height={20} />
        ) : (
          <VerizonIcon width={20} height={20} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export const Signal = ({ t, signal }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t('positionSignal')}: ${formatSignal(signal)}`}>
      <IconButton size="small">
        {signal === 0 ? (
          <SignalCellular0BarIcon fontSize="small" className={classes.negative} />
        ) : signal === 1 ? (
          <SignalCellular1BarIcon fontSize="small" className={classes.medium} />
        ) : signal === 2 ? (
          <SignalCellular2BarIcon fontSize="small" className={classes.medium} />
        ) : signal === 3 ? (
          <SignalCellular3BarIcon fontSize="small" className={classes.positive} />
        ) : (
          <SignalCellular4BarIcon fontSize="small" className={classes.positive} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export const GPS = ({ t, hdop }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t('positionGps')}: ${formatHDOP(hdop)}`}>
      <IconButton size="small">
        {hdop === 0 ? (
          (<LocationOffIcon fontSize="small" className={classes.negative} />)
        ) : hdop <= 2 ? (
          (<LocationOnIcon fontSize="small" className={classes.positive} />)
        ) : hdop <= 10 ? (
          (<LocationOnIcon fontSize="small" className={classes.medium} />)
        ) : (
          (<LocationOnIcon fontSize="small" className={classes.negative} />)
        )}
      </IconButton>
    </Tooltip>
  );
};

export const Power = ({ t, power }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t('positionPower')}: ${formatVoltage(power)}`}>
      <IconButton size="small">
        {power > 10 ? (
          (<PowerIcon fontSize="small" className={classes.positive} />)
        ) : power > 7 ? (
          (<PowerIcon fontSize="small" className={classes.medium} />)
        ) : power > 1 ? (
          (<PowerIcon fontSize="small" className={classes.negative} />)
        ) : (
          (<PowerOffIcon fontSize="small" className={classes.negative} />)
        )}
      </IconButton>
    </Tooltip>
  );
};

export const Battery = ({ t, batteryLevel, charging }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t('positionBattery')}: ${formatPercentage(batteryLevel)}`}>
      <IconButton size="small">
        {batteryLevel > 70 ? (
          charging
            ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
            : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
        ) : batteryLevel > 30 ? (
          charging
            ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
            : (<Battery60Icon fontSize="small" className={classes.medium} />)
        ) : (
          charging
            ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
            : (<Battery20Icon fontSize="small" className={classes.negative} />)
        )}
      </IconButton>
    </Tooltip>
  );
};

export const Ignition = ({ t, ignition }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t(formatIgnition(ignition))}`}>
      <IconButton size="small">
        {ignition ? (
          <EngineIcon width={20} height={20} className={classes.positive} />
        ) : (
          <EngineIcon width={20} height={20} className={classes.neutral} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export const Motion = ({ t, motion }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t(formatMotion(motion))}`}>
      <IconButton size="small">
        <ReportIcon fontSize="small" className={classes.negative} />
      </IconButton>
    </Tooltip>
  );
};

export const Alarm = ({ t, alarm }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(alarm, t)}`}>
      <IconButton size="small">
        <ErrorIcon fontSize="small" className={classes.negative} />
      </IconButton>
    </Tooltip>
  );
};

export const MessageDisplay = ({ message, setMessage, severity }) => {
  const open = !!message;
  return (
    <Snackbar
      open={open}
      onClose={() => setMessage(null)}
      autoHideDuration={snackBarDurationLongMs}
    >
      <Alert onClose={() => setMessage(null)} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export const Impound = ({ t }) => {
  const classes = useGlobalStyles();
  return (
    <Tooltip title={`${t('deviceConditionImpounded')}`}>
      <IconButton size="small">
        <ImpoundLotIcon fontSize="small" className={classes.negative} />
      </IconButton>
    </Tooltip>
  );
};
