import React from 'react';
import {
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ReactComponent as GoogleMapIcon } from '../../resources/images/data/googlemaps.svg';
import { ReactComponent as BingIcon } from '../../resources/images/data/bing.svg';
import { ReactComponent as StreetviewIcon } from '../../resources/images/data/streetview.svg';
import { useTranslation } from './LocalizationProvider';

const getMapLink = (type, latitude, longitude, address, name) => {
  const encode = (str) => encodeURIComponent(str).replace(/%20/g, '+');
  if (latitude && longitude) {
    switch (type) {
      case 'google':
        return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      case 'bing':
        return `https://www.bing.com/maps?cp=${latitude}~${longitude}&lvl=16&style=r&where1=${latitude},${longitude}`;
      case 'street':
        return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
      default:
        return '#';
    }
  } else if (address) {
    const query = name ? `${encode(name)},+${encode(address)}` : encode(address);
    switch (type) {
      case 'google':
        return `https://www.google.com/maps/search/?api=1&query=${query}`;
      case 'bing':
        return `https://www.bing.com/maps?q=${query}`;
      case 'street':
        return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${query}`;
      default:
        return '#';
    }
  }
  return '#';
};

const getMapIcon = (type) => {
  switch (type) {
    case 'google':
      return <GoogleMapIcon />;
    case 'bing':
      return <BingIcon />;
    case 'street':
      return <StreetviewIcon />;
    default:
      return null;
  }
};

const getMapText = (type) => {
  const t = useTranslation();
  switch (type) {
    case 'google':
      return t('linkGoogleMaps');
    case 'bing':
      return t('linkBingMaps');
    case 'street':
      return t('linkStreetView');
    default:
      return '';
  }
};

const MapIconButton = ({ latitude, longitude, address, name, type, color, disabled }) => {
  const link = getMapLink(type, latitude, longitude, address, name);

  return (
    <IconButton
      component="a"
      href={disabled ? '#' : link}
      target="_blank"
      rel="noopener noreferrer"
      color={color || 'default'}
      disabled={disabled} // This will also visually disable the button
    >
      {getMapIcon(type)}
    </IconButton>
  );
};

const MapMenuItem = ({ latitude, longitude, address, name, type }) => {
  const link = getMapLink(type, latitude, longitude, address, name);

  return (
    <MenuItem component="a" target="_blank" href={link}>
      <ListItemIcon>
        {getMapIcon(type)}
      </ListItemIcon>
      <ListItemText>{getMapText(type)}</ListItemText>
    </MenuItem>
  );
};

export { MapIconButton, MapMenuItem };
