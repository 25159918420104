import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    selectedId: null,
    filter: '',
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach((item) => {
        if (state.uniqueId != null) {
          if (item.uniqueId === state.uniqueId) {
            state.items[item.id] = item;
          }
        } else {
          state.items[item.id] = item;
        }
      });
    },
    update(state, action) {
      const { payload } = action;
      const upperFilter = state.filter.toUpperCase();
      payload.forEach((item) => {
        if (
          state.filter === '' ||
          [item.name, item.uniqueId, item.phone, item.contact, item.address, item.vin].some(
            (s) => s && s.toUpperCase().includes(upperFilter),
          )
        ) {
          state.items[item.id] = item;
        }
      });
    },
    select(state, action) {
      state.selectedId = action.payload;
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    filter(state, action) {
      state.filter = action.payload.toUpperCase();
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
