import React from 'react';
import {
  Table,
  TableBody,
} from '@mui/material';
import DialogView from './DialogView';
import PositionValue from './PositionValue';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { useTranslation } from './LocalizationProvider';
import StatusRow from './StatusRow';
import { useAttributePreference } from '../util/preferences';
import { formatTime, formatDistance, get30DayDistanceColor } from '../util/formatter';
import { useGlobalStyles } from '../util/classes';

const PropsDialog = ({ device, position, open, handleClose }) => {
  const classes = useGlobalStyles();
  const t = useTranslation();
  const positionAttributes = usePositionAttributes(t);
  const distanceUnit = useAttributePreference('distanceUnit');
  return (
    <DialogView
      position={position}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedInformation')}
      cancelTitle={t('sharedClose')}
      content={position && (
        <Table size="small">
          <TableBody>
            <StatusRow
              key="deviceIdentifier"
              name={t('deviceIdentifier')}
              content={`${device.uniqueId}`}
            />
            <StatusRow
              key="deviceInstallTime"
              name={t('deviceInstallTime')}
              content={formatTime(device.installTime, 'date', false)}
            />
            <StatusRow
              key="longitude"
              name={positionAttributes.longitude.name}
              content={(
                <PositionValue
                  position={position}
                  property="longitude"
                />
            )}
            />
            <StatusRow
              key="latitude"
              name={positionAttributes.latitude.name}
              content={(
                <PositionValue
                  position={position}
                  property="latitude"
                />
            )}
            />
            <StatusRow
              key="fixTime"
              name={positionAttributes.fixTime.name}
              content={(
                <PositionValue
                  position={position}
                  property="fixTime"
                />
            )}
            />
            <StatusRow
              key="serverTime"
              name={positionAttributes.serverTime.name}
              content={(
                <PositionValue
                  position={position}
                  property="serverTime"
                />
            )}
            />
            <StatusRow
              key="totalDistance"
              name={t('sharedTraveled')}
              content={formatDistance(position.attributes.totalDistance, distanceUnit, t)}
            />
            {device.originalDistance !== 0 && (
              <StatusRow
                key="vehicleTotalDistance"
                name={t('sharedTotal')}
                content={formatDistance(position.attributes.totalDistance + device.originalDistance, distanceUnit, t)}
              />
            )}
            {device.thirtyDayDistance !== 0 && (
              <StatusRow
                key="sharedLast30Days"
                name={t('sharedLast30Days')}
                content={(<div className={classes[get30DayDistanceColor(device.thirtyDayDistance, distanceUnit)]}>{formatDistance(device.thirtyDayDistance, distanceUnit, t)}</div>)}
              />
            )}
          </TableBody>
        </Table>
      )}
    />
  );
};

export default PropsDialog;
