import React from 'react';
import {
  useMediaQuery,
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  cell: {
    width: '20%',
    borderBottom: 'none',
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

export const VehicleView = ({ device }) => {
  const theme = useTheme();
  const t = useTranslation();

  if (!useMediaQuery(theme.breakpoints.down('lg'))) {
    return (
      <>
        <StatusRow
          name={t('vehicleVIN')}
          content={device.vin || ''}
        />
        <StatusRow
          name={t('vehicleYear')}
          content={device.vehicleYear || ''}
        />
        <StatusRow
          name={t('vehicleMake')}
          content={device.vehicleMake || ''}
        />
        <StatusRow
          name={t('vehicleModel')}
          content={device.vehicleModel || ''}
        />
        <StatusRow
          name={t('vehiclePlate')}
          content={device.vehiclePlate || ''}
        />
        <StatusRow
          name={t('vehicleState')}
          content={device.vehicleState || ''}
        />
      </>
    );
  }
  return <div />;
};

export default VehicleView;

export const ContactView = ({ device }) => {
  const theme = useTheme();
  const t = useTranslation();

  if (!useMediaQuery(theme.breakpoints.down('lg'))) {
    return (
      <>
        <StatusRow
          name={t('deviceContact')}
          content={device.contact || ''}
        />
        <StatusRow
          name={t('sharedPhone')}
          content={device.phone || ''}
        />
        <StatusRow
          name={t('sharedAddress')}
          content={device.address || ''}
        />
        <StatusRow
          name={t('sharedCity')}
          content={device.city || ''}
        />
        <StatusRow
          name={t('sharedState')}
          content={device.state || ''}
        />
        <StatusRow
          name={t('sharedZip')}
          content={device.zip || ''}
        />
      </>
    );
  }
  return <div />;
};

export const VehicleData = (device) => {
  const fields = [
    { id: 'vehicleVIN', value: (device.vin || '') },
    { id: 'vehicleYear', value: (device.vehicleYear || '') },
    { id: 'vehicleMake', value: (device.vehicleMake || '') },
    { id: 'vehicleModel', value: (device.vehicleModel || '') },
    { id: 'vehiclePlate', value: (device.vehiclePlate || '') },
    { id: 'vehicleState', value: (device.vehicleState || '') },
  ];
  return fields;
};

export const ContactData = (device) => {
  const fields = [
    { id: 'deviceContact', value: (device.contact || '') },
    { id: 'sharedPhone', value: (device.phone || '') },
    { id: 'sharedAddress', value: (device.address || '') },
    { id: 'sharedCity', value: (device.city || '') },
    { id: 'sharedState', value: (device.state || '') },
    { id: 'sharedZip', value: (device.zip || '') },
  ];
  return fields;
};
