import React from 'react';
import {
  TableRow, TableCell,
} from '@mui/material';
import { useTranslation } from './LocalizationProvider';
import { useGlobalStyles } from '../util/classes';
import { getSignal, getOperator } from './CommonValues';
import {
  Operator, Signal, GPS, Power, Battery, Ignition, Motion, Alarm, Impound,
} from './CommonObjects';

const StandardProps = ({ status, position, device }) => {
  const classes = useGlobalStyles();
  const t = useTranslation();

  return (
    <TableRow>
      {status === 'online' && position && (
        <TableCell colSpan={2} className={classes.cell}>
          {position.attributes.hasOwnProperty('io1') && position.attributes.hasOwnProperty('io2') && (
            <Operator t={t} operator={getOperator(position.attributes.io1, position.attributes.io2)} />
          )}
          {position.attributes.hasOwnProperty('rssi') && (
            <Signal t={t} signal={getSignal(position.attributes.rssi)} />
          )}
          {position.attributes.hasOwnProperty('hdop') && (
            <GPS t={t} hdop={position.attributes.hdop} />
          )}
          {position.attributes.hasOwnProperty('power') && (
            <Power t={t} power={position.attributes.power} />
          )}
          {position.attributes.hasOwnProperty('batteryLevel') && (
            <Battery t={t} batteryLevel={position.attributes.batteryLevel} charging={position.attributes.charge} />
          )}
          {position.attributes.hasOwnProperty('ignition') && (
            <Ignition t={t} ignition={position.attributes.ignition} />
          )}
          {position.attributes.hasOwnProperty('motion') && !position.attributes.motion && (
            <Motion t={t} motion={position.attributes.motion} />
          )}
          {position.attributes.hasOwnProperty('alarm') && (
            <Alarm t={t} alarm={position.attributes.alarm} />
          )}
          {device.impoundTime !== null && (
            <Impound t={t} />
          )}
        </TableCell>
      )}
      {status !== 'online' && position && (
        <TableCell colSpan={2} className={classes.cell}>
          <Signal t={t} signal={0} />
          {position.attributes.hasOwnProperty('power') && (
            <Power t={t} power={position.attributes.power} />
          )}
          {position.attributes.hasOwnProperty('batteryLevel') && (
            <Battery t={t} batteryLevel={position.attributes.batteryLevel} charging={position.attributes.charge} />
          )}
          {position.attributes.hasOwnProperty('alarm') && (
            <Alarm t={t} alarm={position.attributes.alarm} />
          )}
          {device.impoundTime !== null && (
            <Impound t={t} />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default StandardProps;
