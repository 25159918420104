import React from 'react';
import {
  formatTime,
  formatDistance,
  formatHours,
  formatVolume,
  formatSpeed,
} from '../../common/util/formatter';
import AddressValue from '../../common/components/AddressValue';
import { handleErrorResponse } from '../../common/util/utils'; // Adjust the import path accordingly

export const formatValue = (item, key, options) => {
  const { hours12, distanceUnit, volumeUnit, speedUnit, t } = options;

  const value = item[key];

  switch (key) {
    case 'startTime':
    case 'endTime':
      return formatTime(value, 'minutes', hours12);

    case 'startOdometer':
    case 'endOdometer':
    case 'distance':
      return formatDistance(value, distanceUnit, t);

    case 'averageSpeed':
    case 'maxSpeed':
      return formatSpeed(value, speedUnit, t);

    case 'duration':
    case 'engineHours':
      return formatHours(value);

    case 'spentFuel':
      return formatVolume(value, volumeUnit, t);

    case 'address':
      return <AddressValue latitude={item.latitude} longitude={item.longitude} originalAddress={value} />;

    case 'startAddress':
      return <AddressValue latitude={item.startLat} longitude={item.startLon} originalAddress={value} />;

    case 'endAddress':
      return <AddressValue latitude={item.endLat} longitude={item.endLon} originalAddress={value} />;

    default:
      return value;
  }
};

export const handleReportSubmit = async (deviceId, from, to, type, endpoint, setItems, setLoading) => {
  const query = new URLSearchParams({ deviceId, from, to });

  if (type === 'export') {
    window.location.assign(`${endpoint}/xlsx?${query.toString()}`);
  } else if (type === 'mail') {
    const response = await fetch(`${endpoint}/mail?${query.toString()}`);
    if (!response.ok) {
      await handleErrorResponse(response);
    }
  } else {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}?${query.toString()}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        setItems(data);
      } else {
        await handleErrorResponse(response);
      }
    } finally {
      setLoading(false);
    }
  }
};
