import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import { useGlobalStyles } from '../util/classes';
import { prefixString } from '../util/stringUtils';

const NotificationsDialog = ({ deviceId, open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();
  const [notifications, setNotifications] = useState();

  const validate = () => true;

  const setAssignments = async (notifications) => {
    fetch(`/api/notifications?deviceId=${deviceId}`)
      .then((response) => response.json())
      .then((assignedNotifications) => {
        assignedNotifications.forEach((assignedNotification) => {
          notifications.forEach((notification) => {
            if (notification.type === assignedNotification.type) {
              notification.origAssigned = true;
              notification.newAssigned = true;
              notification.id = assignedNotification.id;
            }
          });
        });
        setNotifications(notifications);
      });
  };

  useEffect(() => {
    async function fetchData() {
      fetch('/api/notifications/types')
        .then((response) => response.json())
        .then((notifications) => {
          setAssignments(notifications);
        });
    }
    fetchData();
  }, [deviceId]);

  const handleOnChange = (index) => {
    notifications[index].newAssigned = !notifications[index].newAssigned;
    const newNotifications = [...notifications];
    setNotifications(newNotifications);
  };

  return (
    <DialogView
      item={notifications}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedNotifications')}
      validate={validate}
      acceptTitle={t('sharedSave')}
      content={notifications && (
        <Table size="small">
          <TableBody>
            {!notifications && (
              <TableRow>
                <TableCell className={classes.cell}>
                  {t('sharedLoading')}
                </TableCell>
              </TableRow>
            )}
            {notifications && notifications.map((notification, index) => (
              <TableRow key={notification.type}>
                <TableCell className={classes.cell}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={notification.newAssigned} onChange={() => handleOnChange(index)} />}
                      label={t(prefixString('event', notification.type))}
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    />
  );
};

export default NotificationsDialog;
