// bufferMiddleware.js
import { sessionActions } from './session';

const throttleMiddleware = (store) => {
  let intervalId = null;

  const startInterval = () => {
    if (intervalId === null) {
      intervalId = setInterval(() => {
        const state = store.getState();
        if (state.session.positionsBuffer.length > 0) {
          store.dispatch(sessionActions.flushBufferToPositions());
        } else {
          clearInterval(intervalId);
          intervalId = null;
        }
      }, 1000); // Flush buffer every second
    }
  };

  return (next) => (action) => {
    if (action.type === 'session/updatePositions') {
      startInterval();
    }

    return next(action);
  };
};

export default throttleMiddleware;
