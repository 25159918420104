import React, { useState } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import DialogView from './DialogView';
import { useAttributePreference } from '../util/preferences';
import { useTranslation } from './LocalizationProvider';
import { useGlobalStyles } from '../util/classes';
import { speedUnitString } from '../util/converter';

const SpeedLimitDialog = ({ open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();
  const [item, setItem] = useState();
  const speedUnit = useAttributePreference('speedUnit');

  const validate = () => item && item.speed;

  if (!item) {
    setItem({ speed: '' });
  }

  return (
    <DialogView
      item={item}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('attributeSpeedLimit')}
      validate={validate}
      acceptTitle={t('sharedSet')}
      content={item && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  type="number"
                  value={item.speed || ''}
                  onChange={(event) => setItem({ ...item, speed: event.target.value })}
                  label={`${t('positionSpeed')} (${speedUnitString(speedUnit, t)})`}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    />
  );
};

export default SpeedLimitDialog;
