import { useEffect, useState } from 'react';
import { useTranslation } from './LocalizationProvider';
import { useCatch } from '../../reactHelper';
import { handleErrorResponse } from '../util/utils';

const AddressValue = ({ latitude, longitude, originalAddress }) => {
  const t = useTranslation();

  // const addressEnabled = useSelector((state) => state.session.server.geocoderEnabled);

  const [address, setAddress] = useState();

  const showAddress = useCatch(async () => {
    const query = new URLSearchParams({ latitude, longitude });
    const response = await fetch(`/api/server/geocode?${query.toString()}`);
    if (response.ok) {
      setAddress(await response.text());
    } else {
      await handleErrorResponse(response);
    }
  });

  useEffect(() => {
    if (originalAddress == null) {
      showAddress();
      setAddress(t('sharedLoading'));
    } else {
      setAddress(originalAddress);
    }
  }, [latitude, longitude, originalAddress]);

  if (address) {
    return address;
  }
  // if (addressEnabled) {
  //   return (<Link href="#" onClick={showAddress}>{t('sharedShowAddress')}</Link>);
  // }
  return '';
};

export default AddressValue;
