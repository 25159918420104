import React from 'react';
import {
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  labelCell: {
    width: '40%',
    borderBottom: 'none',
  },
  valueCell: {
    width: '60%',
    borderBottom: 'none',
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

const StatusRow = ({ name, content, bold = false }) => {
  const classes = useStyles();
  const displayContent = content && content !== null ? content : '';

  return (
    <TableRow>
      <TableCell className={classes.labelCell}>
        <Typography variant="body2" className={bold ? classes.boldText : ''}>
          {name}
        </Typography>
      </TableCell>
      <TableCell className={classes.valueCell}>
        <Typography component="div" variant="body2" color="textSecondary" className={bold ? classes.boldText : ''}>
          {displayContent}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default StatusRow;
