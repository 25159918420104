import React from 'react';
import {
  TableCell,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Divider,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MapIconButton } from './MapElement';
import { useTranslation } from './LocalizationProvider';
import { formatJsonToPlainText } from '../util/formatter';

const JsonPrettyPrinter = ({ data }) => {
  const t = useTranslation();

  const handleCopyToClipboard = (jsonObject) => {
    const plainText = formatJsonToPlainText(jsonObject);
    navigator.clipboard.writeText(plainText);
  };

  const formatJsonObject = (jsonObject, indent = 0) => {
    const indentStr = ' '.repeat(indent);
    const entries = Object.entries(jsonObject).filter(([key]) => key !== 'latitude' && key !== 'longitude');
    return entries.map(([key, value]) => {
      if (key === 'Address') {
        const { latitude, longitude } = jsonObject;
        return (
          <div key={key} style={{ marginBottom: '10px' }}>
            <TableCell
              style={{
                display: 'flex',
                flexDirection: 'column', // Ensure column layout
                alignItems: 'flex-start',
                fontSize: '1.1em',
                fontWeight: 'bold',
                borderBottom: 'none',
                padding: 0,
              }}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginBottom: '8px', lineHeight: '1.5', fontWeight: 'bold' }}
              >
                {value}
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <MapIconButton longitude={longitude} latitude={latitude} type="google" />
                <MapIconButton longitude={longitude} latitude={latitude} type="bing" />
                <MapIconButton longitude={longitude} latitude={latitude} type="street" />
                <Tooltip title={t('sharedCopyToClipboard')}>
                  <IconButton
                    onClick={() => handleCopyToClipboard(jsonObject)}
                    color="default"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </TableCell>
          </div>
        );
      }
      if (typeof value === 'object' && value !== null) {
        return (
          <div key={key}>
            <Typography variant="body2" color="textSecondary" component="span" style={{ fontWeight: 'bold' }}>
              {`${indentStr}${key}:`}
            </Typography>
            <div style={{ marginLeft: '20px' }}>
              {formatJsonObject(value, indent + 2)}
            </div>
          </div>
        );
      }
      return (
        <div key={key}>
          <Typography variant="body2" color="textSecondary" component="span" style={{ fontWeight: 'bold' }}>
            {`${indentStr}${key}: `}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="span">
            {value}
          </Typography>
        </div>
      );
    });
  };

  return (
    <div>
      {data.map((jsonObject, index) => (
        <div key={jsonObject.Address} style={{ marginBottom: '20px' }}>
          {formatJsonObject(jsonObject)}
          {index < data.length - 1 && <Divider style={{ margin: '8px 0' }} />}
        </div>
      ))}
    </div>
  );
};

export default JsonPrettyPrinter;
