import React, { useState } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import { useAttributePreference } from '../util/preferences';
import usePositionAttributes from '../attributes/usePositionAttributes';
import {
  speedFromKnots, speedToKnots, distanceFromMeters, distanceToMeters, distanceUnitString,
} from '../util/converter';
import { useGlobalStyles } from '../util/classes';

const MaintenanceDialog = ({ open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();
  const [item, setItem] = useState();
  const speedUnit = useAttributePreference('speedUnit', 'kn');
  const distanceUnit = useAttributePreference('distanceUnit', 'km');
  const positionAttributes = usePositionAttributes(t);

  const rawToValue = (value) => {
    const attribute = positionAttributes[item.type];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return speedFromKnots(value, speedUnit);
        case 'distance':
          return distanceFromMeters(value, distanceUnit);
        default:
          return value;
      }
    }
    return value;
  };

  const valueToRaw = (value) => {
    const attribute = positionAttributes[item.type];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return speedToKnots(value, speedUnit);
        case 'distance':
          return distanceToMeters(value, distanceUnit);
        default:
          return value;
      }
    }
    return value;
  };

  const validate = () => item && item.name && item.type && item.start && item.period;

  if (!item) {
    setItem({ name: '', type: 'totalDistance', start: '', period: '' });
  }

  return (
    <DialogView
      item={item}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedMaintenance')}
      validate={validate}
      acceptTitle={t('sharedAdd')}
      content={item && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  value={item.name || ''}
                  label={t('sharedName')}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  type="number"
                  value={rawToValue(item.start) || ''}
                  onChange={(event) => setItem({ ...item, start: valueToRaw(event.target.value) })}
                  label={`${t('maintenanceStart')} (${distanceUnitString(distanceUnit, t)})`}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  type="number"
                  value={rawToValue(item.period) || ''}
                  onChange={(event) => setItem({ ...item, period: valueToRaw(event.target.value) })}
                  label={`${t('maintenancePeriod')} (${distanceUnitString(distanceUnit, t)})`}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    />
  );
};

export default MaintenanceDialog;
