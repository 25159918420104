import React, { useState } from 'react';
import {
  Button, FormControl, TextField, List, ListItem, ListItemText, Box, CircularProgress, Divider, IconButton, Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ToolsMenu from './components/ToolsMenu';
import useReportStyles from '../reports/common/useReportStyles';
import { MapIconButton } from '../common/components/MapElement';
import { handleErrorResponse } from '../common/util/utils';

const FindServicePage = ({ endpoint, breadcrumbs }) => {
  const classes = useReportStyles();
  const t = useTranslation();

  const [location, setLocation] = useState('');
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/tools/${endpoint}?location=${encodeURIComponent(location)}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        setMatches(data);
      } else {
        await handleErrorResponse(response);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <PageLayout menu={<ToolsMenu />} breadcrumbs={breadcrumbs}>
      <Box className={classes.filter} display="flex" alignItems="center" mb={2}>
        <FormControl className={classes.filterItem} style={{ maxWidth: '400px' }} fullWidth>
          <TextField
            label={t('sharedLocation')}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            onKeyPress={handleKeyPress}
            variant="outlined"
          />
        </FormControl>
        <Box ml={1} display="flex" alignItems="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSearch}
            className={classes.filterButton}
            disabled={loading}
          >
            {t('reportShow')}
          </Button>
          {loading && <CircularProgress size={24} style={{ marginLeft: '10px' }} />}
        </Box>
      </Box>
      <List style={{ margin: '0 16px' }}>
        {matches.map((match, index) => (
          <React.Fragment key={match.name + match.address}>
            <ListItem>
              <ListItemText
                primary={match.name}
                secondary={(
                  <>
                    <div>{match.address}</div>
                    {/* <div>{match.phone}</div> */}
                    <Box mt={1} display="flex" gap={1} alignItems="center">
                      <MapIconButton address={match.address} name={match.name} type="google" />
                      <MapIconButton address={match.address} name={match.name} type="bing" />
                      <Tooltip title={t('sharedCopyToClipboard')}>
                        <IconButton
                          onClick={() => handleCopyToClipboard(`${match.name}\n${match.address}\n${match.phone}`)}
                          color="default"
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </>
                )}
              />
            </ListItem>
            {index < matches.length - 1 && <Divider style={{ margin: '8px 0' }} />}
          </React.Fragment>
        ))}
      </List>
    </PageLayout>
  );
};

export default FindServicePage;
