import React, { useState } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import { useAttributePreference } from '../util/preferences';
import { useGlobalStyles } from '../util/classes';
import { altitudeUnitString } from '../util/converter';

const GeofenceDialog = ({ open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();
  const [item, setItem] = useState({ name: '', radius: '50', address: '' });
  const [locationOption, setLocationOption] = useState('currentLocation');
  const radiusUnit = useAttributePreference('altitudeUnit');

  const validate = () => item && item.name && item.radius;

  const handleLocationChange = (event) => {
    setLocationOption(event.target.value);
    if (event.target.value === 'currentLocation') {
      setItem({ ...item, name: '', radius: 50, address: '' });
    }
  };

  return (
    <DialogView
      item={item}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedGeofence')}
      validate={validate}
      acceptTitle={t('sharedAdd')}
      content={item && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="location"
                    name="location"
                    value={locationOption}
                    onChange={handleLocationChange}
                  >
                    <FormControlLabel
                      value="currentLocation"
                      control={<Radio />}
                      label={t('mapCurrentLocation')}
                    />
                    <FormControlLabel
                      value="address"
                      control={<Radio />}
                      label={t('sharedAddress')}
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <Box
                  style={{
                    visibility: locationOption === 'address' ? 'visible' : 'hidden',
                    height: locationOption === 'address' ? 'auto' : 0,
                    marginTop: 8,
                    overflow: 'hidden',
                  }}
                >
                  <TextField
                    fullWidth
                    value={item.address || ''}
                    label={t('sharedAddress')}
                    onChange={(event) => setItem({ ...item, address: event.target.value })}
                    disabled={locationOption !== 'address'}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  value={item.name || ''}
                  label={t('sharedName')}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  type="number"
                  value={item.radius || ''}
                  onChange={(event) => setItem({ ...item, radius: event.target.value })}
                  label={`${t('commandRadius')} (${altitudeUnitString(radiusUnit, t)})`}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    />
  );
};

export default GeofenceDialog;
