import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from './LocalizationProvider';
import { ContactData, VehicleData } from './DeviceValue';
import { useAttributePreference } from '../util/preferences';
import usePositionAttributes from '../attributes/usePositionAttributes';
import StandardProps from './StandardProps';
import StatusRow from './StatusRow';
import PositionValue from './PositionValue';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cardCell: {
    [theme.breakpoints.up('lg')]: {
      width: '33%',
    },
    borderBottom: 'none',
  },
  tabLabel: {
    textTransform: 'none',
    minWidth: '33%',
  },
}));

const InfoTab = ({ t, classes, device, position }) => {
  const positionAttributes = usePositionAttributes(t);
  const positionItems = useAttributePreference('positionItems', 'speed,address,totalDistance,lastStop,serverTime');

  return (
    <Table size="small" classes={{ root: classes.table }}>
      {position && (
      <TableBody>
        <StandardProps status={device.status} position={position} device={device} />
        {positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
          <StatusRow
            key={key}
            name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
            content={(
              <PositionValue
                position={position}
                property={position.hasOwnProperty(key) ? key : null}
                attribute={position.hasOwnProperty(key) ? null : key}
              />
          )}
          />
        ))}
      </TableBody>
      )}
    </Table>
  );
};

const VehicleTab = ({ t, classes, device }) => {
  const vehicleData = VehicleData((device));
  return (
    <Table size="small" classes={{ root: classes.table }}>
      <TableBody>
        {vehicleData.map((vehicle) => (
          <StatusRow
            key={vehicle.id}
            name={t(vehicle.id)}
            content={vehicle.value}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const ContactTab = ({ t, classes, device }) => {
  const contactData = ContactData((device));
  return (
    <Table size="small" classes={{ root: classes.table }}>
      <TableBody>
        {contactData.map((contact) => (
          <StatusRow
            key={contact.id}
            name={t(contact.id)}
            content={contact.value}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const StatusTabPanel = ({ largeScreen, device, position }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const t = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    !largeScreen ? (
      <>
        <Tabs value={currentTabIndex} onChange={handleChange} TabIndicatorProps={{ style: { transition: 'none' } }}>
          <Tab disableRipple label={t('sharedInfoTitle')} className={classes.tabLabel} />
          <Tab disableRipple label={t('sharedVehicle')} className={classes.tabLabel} />
          <Tab disableRipple label={t('deviceContact')} className={classes.tabLabel} />
        </Tabs>
        {currentTabIndex === 0 && (
          <InfoTab
            t={t}
            classes={classes}
            device={device}
            position={position}
          />
        )}
        {currentTabIndex === 1 && (
          <VehicleTab
            t={t}
            classes={classes}
            device={device}
          />
        )}
        {currentTabIndex === 2 && (
          <ContactTab
            t={t}
            classes={classes}
            device={device}
          />
        )}
      </>
    ) : (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.cardCell}>
              <InfoTab
                t={t}
                classes={classes}
                device={device}
                position={position}
              />
            </TableCell>
            <TableCell className={classes.cardCell}>
              <VehicleTab
                t={t}
                classes={classes}
                device={device}
              />
            </TableCell>
            <TableCell className={classes.cardCell}>
              <ContactTab
                t={t}
                classes={classes}
                device={device}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  );
};

export default StatusTabPanel;
