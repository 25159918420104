import React, {
  useState,
  useCallback,
} from 'react';
import {
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ReportFilter from './components/ReportFilter';
import ReportsMenu from './components/ReportsMenu';
import ReportMap from './components/ReportMap';
import ReportTable from './components/ReportTable';
import ActionPanel from './components/ActionPanel';
import useReportStyles from './common/useReportStyles';
import { formatValue, handleReportSubmit } from './common/reportUtils';
import useFormatOptions from './common/useFormatOptions';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import { useCatch } from '../reactHelper';
import StopPatternsDialog from './components/StopPatternsDialog';
import StopPredictionsDialog from './components/StopPredictionsDialog';
import StopLocationsDialog from './components/StopLocationsDialog';
import { MapIconButton } from '../common/components/MapElement';

const columnsArray = [
  ['count', 'reportStopCount'],
  ['startTime', 'reportFirstStop'],
  ['endTime', 'reportLastStop'],
  ['address', 'positionAddress'],
  ['duration', 'reportAverageTime'],
];
const columnsMap = new Map(columnsArray);

const TopStopReportPage = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useReportStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const topStopReportIcons = (
    <>
      <MapIconButton
        longitude={selectedItem?.longitude}
        latitude={selectedItem?.latitude}
        type="google"
        disabled={!selectedItem}
      />
      <MapIconButton
        longitude={selectedItem?.longitude}
        latitude={selectedItem?.latitude}
        type="bing"
        disabled={!selectedItem}
      />
      <MapIconButton
        longitude={selectedItem?.longitude}
        latitude={selectedItem?.latitude}
        type="street"
        disabled={!selectedItem}
      />
      <StopPredictionsDialog
        longitude={selectedItem?.longitude}
        latitude={selectedItem?.latitude}
        deviceId={selectedItem?.deviceId}
        from={from}
        to={to}
        numStops={selectedItem?.count}
        disabled={!selectedItem}
      />
      <StopPatternsDialog
        longitude={selectedItem?.longitude}
        latitude={selectedItem?.latitude}
        deviceId={selectedItem?.deviceId}
        from={from}
        to={to}
        title={`${t('reportPatterns')}: ${selectedItem?.address}`}
        disabled={!selectedItem}
      />
      <StopLocationsDialog
        longitude={selectedItem?.longitude}
        latitude={selectedItem?.latitude}
        deviceId={selectedItem?.deviceId}
        from={from}
        to={to}
        title={`${t('reportStops')}: ${selectedItem?.address}`}
        disabled={!selectedItem}
      />
    </>
  );

  const handleResetFilter = () => {
    setSelectedItem(null);
    setItems([]);
  };

  const handleSubmit = useCatch(async ({ deviceId, from, to, type }) => {
    handleResetFilter();
    setFrom(from);
    setTo(to);
    await handleReportSubmit(deviceId, from, to, type, '/api/reports/topstops', setItems, setLoading);
  });

  // Use the custom hook to get the formatting options
  const formatOptions = useFormatOptions();
  const handleFormatValue = (item, key) => formatValue(item, key, formatOptions);

  const handleMarkerClick = useCallback((id) => {
    const item = items.find((i) => i.id === id);
    setSelectedItem(item);
  }, [items]);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportTopStops']}>
      <div className={classes.container}>
        {items.length > 0 && (
          <ReportMap items={items} selectedItem={selectedItem} handleMarkerClick={handleMarkerClick} />
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            {(largeScreen || items.length === 0) && (
              <ReportFilter handleSubmit={handleSubmit} />
            )}
            {items.length > 0 && (
              <div className={classes.actionPanel}>
                <ActionPanel
                  showFilterMenu={!largeScreen}
                  onToggleFilter={handleResetFilter}
                  icons={topStopReportIcons} // Pass the specific icons for StopReportPage
                />

              </div>
            )}
            <Divider />
          </div>
          <ReportTable
            columnsMap={columnsMap}
            items={items}
            loading={loading}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            formatValue={handleFormatValue}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default TopStopReportPage;
