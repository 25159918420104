import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import { useGlobalStyles } from '../util/classes';
import { handleErrorResponse } from '../util/utils';

const GeofenceListDialog = ({ deviceId, open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();

  const [geofences, setGeofences] = useState();

  const validate = () => true;

  useEffect(() => {
    const fetchGeofences = async () => {
      const response = await fetch(`/api/geofences?deviceId=${deviceId}`);
      if (!response.ok) {
        await handleErrorResponse(response);
      }
      const data = await response.json();
      setGeofences(data);
    };

    fetchGeofences();
  }, [deviceId]);

  const handleOnChange = (index) => {
    geofences[index].toDelete = !geofences[index].toDelete;
    const newGeofences = [...geofences];
    setGeofences(newGeofences);
  };

  return (
    <DialogView
      item={geofences}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedGeofences')}
      validate={validate}
      acceptTitle={t('sharedRemove')}
      content={(
        <Table size="small">
          <TableBody>
            {!geofences && (
              <TableRow>
                <TableCell className={classes.cell} align="center">
                  {t('sharedLoading')}
                </TableCell>
              </TableRow>
            )}
            {geofences && geofences.length === 0 && (
              <TableRow>
                <TableCell className={classes.cell} align="center">
                  {t('sharedNoGeofences')}
                </TableCell>
              </TableRow>
            )}
            {geofences && geofences.map((geofence, index) => (
              <TableRow key={geofence.id}>
                <TableCell className={classes.cell}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={geofence.newAssigned} onChange={() => handleOnChange(index)} />}
                      label={geofence.name}
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    />
  );
};

export default GeofenceListDialog;
