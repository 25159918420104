import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemText, ListItemButton } from '@mui/material';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAdministrator } from '../common/util/permissions';
import { useAttributePreference } from '../common/util/preferences';
import { useGlobalStyles } from '../common/util/classes';
import { getSignal } from '../common/components/CommonValues';
import {
  Signal, GPS, Power, Battery, Alarm,
} from '../common/components/CommonObjects';

const DeviceRow = ({ data, index, style }) => {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const geofences = useSelector((state) => state.geofences.items);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  const formatProperty = (key) => {
    if (key === 'geofenceIds') {
      const geofenceIds = item[key] || [];
      return geofenceIds
        .filter((id) => geofences.hasOwnProperty(id))
        .map((id) => geofences[id].name)
        .join(', ');
    }
    return item[key];
  };

  const secondaryText = () => {
    let status;
    let statusColor;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
      statusColor = getStatusColor(item.status);
    } else {
      status = moment(item.lastUpdate).fromNow();
      statusColor = moment().diff(item.lastUpdate, 'hours') > 25 ? getStatusColor('offline') : getStatusColor('unknown');
    }
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${formatProperty(deviceSecondary)} • `}
        <span className={globalClasses[statusColor]}>{status}</span>
      </>
    );
  };

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.select(item.id))}
        disabled={!admin && item.disabled}
        selected={selectedDeviceId === item.id}
      >
        <ListItemText
          primary={formatProperty(devicePrimary)}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {position && (
          <>
            {position.attributes.hasOwnProperty('alarm') && (
              <Alarm t={t} alarm={position.attributes.alarm} />
            )}
            {item.status === 'online' && (
              <>
                {position.attributes.hasOwnProperty('hdop') && (
                  <GPS t={t} hdop={position.attributes.hdop} />
                )}
                {position.attributes.hasOwnProperty('rssi') && (
                  <Signal t={t} signal={getSignal(position.attributes.rssi)} />
                )}
                {position.attributes.hasOwnProperty('power') && (
                  <Power t={t} power={position.attributes.power} />
                )}
              </>
            )}
            {item.status !== 'online' && (
              <>
                {position.attributes.hasOwnProperty('power') && (
                  <Power t={t} power={position.attributes.power} />
                )}
                {position.attributes.hasOwnProperty('batteryLevel') && (
                  <Battery t={t} batteryLevel={position.attributes.batteryLevel} charging={position.attributes.charge} />
                )}
              </>
            )}
          </>
        )}
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;
