import React, { useRef, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TableShimmer from '../../common/components/TableShimmer';
import { useTranslation } from '../../common/components/LocalizationProvider';

const ReportTable = ({ columnsMap, items, loading, selectedItem, setSelectedItem, formatValue }) => {
  const t = useTranslation();
  const rowRefs = useRef([]);

  useEffect(() => {
    if (selectedItem) {
      const index = items.findIndex((item) => item === selectedItem);
      if (index !== -1 && rowRefs.current[index]) {
        rowRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedItem, items]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {/* Iterate over columnsMap to generate TableCell headers */}
          {[...columnsMap.keys()].map((key) => (
            <TableCell key={key}>{t(columnsMap.get(key))}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading ? (
          items.map((item, index) => (
            <TableRow
              key={item.positionId}
              ref={(el) => (rowRefs.current[index] = el)}
              onClick={() => {
                if (selectedItem === item) {
                  setSelectedItem(null); // Deselect if the same item is clicked
                } else {
                  setSelectedItem(item); // Select the item if a different one is clicked
                }
              }}
              selected={selectedItem === item}
              style={{
                backgroundColor: selectedItem === item ? '#f0f0f0' : 'inherit',
                cursor: 'pointer',
              }}
            >
              {/* Iterate over columnsMap to generate TableCell body */}
              {[...columnsMap.keys()].map((key) => (
                <TableCell key={key}>{formatValue(item, key)}</TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableShimmer columns={columnsMap.size} />
        )}
      </TableBody>
    </Table>
  );
};

export default ReportTable;
