import React from 'react';
import { Chart, registerables } from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { usePreference } from '../../common/util/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { formatTime } from '../../common/util/formatter';

Chart.register(...registerables);

const HeatMap = ({ data, timeSlotCount }) => {
  const t = useTranslation();
  const hours12 = usePreference('twelveHourFormat');

  const maxBubbleSize = 15; // Reduced maximum radius for the largest bubble to prevent overlap

  const heatMapData = {
    datasets: [{
      data: data.map((item) => {
        const slotKey = `${item.day}-${item.hour}`;
        const totalPossibleStops = timeSlotCount[slotKey] || 1; // Avoid division by zero
        let percentage = (item.value / totalPossibleStops) * 100;
        if (percentage > 100) {
          percentage = 100;
        }

        return {
          x: item.hour + 0.5, // Center the bubble in the middle of the hour
          y: item.day + 0.5, // Center the bubble in the middle of the day
          r: (percentage / 100) * maxBubbleSize, // Scale the bubble size based on the percentage
          rawValue: { stops: item.value, percentage }, // Use shorthand for percentage
          borderWidth: 1,
        };
      }),
    }],
  };

  const options = {
    aspectRatio: 3, // Adjust this to ensure square-like grid
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 0,
        max: 24,
        ticks: {
          stepSize: 1,
          callback(value) {
            const date = new Date();
            date.setHours(value, 0, 0, 0);
            return formatTime(date, 'hour', hours12);
          },
        },
        title: {
          display: true,
          text: t('positionHours'),
        },
      },
      y: {
        type: 'linear',
        min: 0.5,
        max: 6.5,
        ticks: {
          stepSize: 1,
          callback(value) {
            const dayIndex = value - 0.5;
            const dayNames = [
              t('calendarSunday'),
              t('calendarMonday'),
              t('calendarTuesday'),
              t('calendarWednesday'),
              t('calendarThursday'),
              t('calendarFriday'),
              t('calendarSaturday'),
            ];
            return dayNames[dayIndex];
          },
        },
        title: {
          display: true,
          text: t('calendarDays'),
        },
        offset: true, // Ensures labels are centered in the slot
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the chart label
      },
      tooltip: {
        callbacks: {
          label(context) {
            const { stops, percentage } = context.raw.rawValue; // Destructure stops and percentage from context.raw.rawValue
            return `${t('reportStops')} ${stops}, ${t('reportFrequency')} ${percentage.toFixed(2)}%`;
          },
        },
      },
    },
  };

  return <Bubble data={heatMapData} options={options} style={{ width: '100%', height: '350px' }} />;
};

export default HeatMap;
