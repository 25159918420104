import React from 'react';
import {
  Table,
  TableBody,
} from '@mui/material';
import { useTranslation } from './LocalizationProvider';
import DialogView from './DialogView';
import StatusRow from './StatusRow';

const TerminateDialog = ({
  title, open, device, handleClose,
}) => {
  const t = useTranslation();

  return (
    <DialogView
      item={device}
      open={open}
      handleClose={handleClose}
      dialogTitle={title}
      validate={null}
      acceptTitle={t('sharedYes')}
      cancelTitle={t('sharedNo')}
      content={device && (
        <Table size="small">
          <TableBody>
            <StatusRow
              key="name"
              name={t('sharedName')}
              content={device.name}
            />
            <StatusRow
              key="contact"
              name={t('sharedCustomer')}
              content={device.contact || ''}
            />
            <StatusRow
              key="vehicle"
              name={t('sharedVehicle')}
              content={`${device.vehicleMake || ''} ${device.vehicleModel || ''} ${device.vehicleYear || ''}`}
            />
          </TableBody>
        </Table>
      )}
    />
  );
};

export default TerminateDialog;
