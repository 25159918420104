import moment from 'moment';
import {
  altitudeFromMeters,
  altitudeUnitString,
  distanceFromMeters,
  distanceLongString,
  speedFromKnots,
  speedUnitString,
  volumeFromLiters,
  volumeUnitString,
} from './converter';
import { prefixString } from './stringUtils';

export const formatBoolean = (value, t) => (value ? t('sharedYes') : t('sharedNo'));

export const formatNumber = (value, precision = 1) => Number(value.toFixed(precision));

export const formatPercentage = (value) => `${value}%`;

export const formatVoltage = (value) => `${formatNumber(value)} V`;

export const formatSignal = (value) => `${formatNumber(value)} Bar`;

export const formatOperator = (value) => {
  switch (value) {
    case 'att':
      return 'AT&T';
    case 'verizon':
      return 'Verizon';
    case 'tmobile':
      return 'T-Mobile';
    default:
      return 'Unknown';
  }
};

export const formatIgnition = (value) => {
  if (value === true) {
    return 'eventIgnitionOn';
  }
  return 'eventIgnitionOff';
};

export const formatMotion = (value) => {
  if (value === true) {
    return 'eventDeviceMoving';
  }
  return 'eventDeviceStopped';
};

export const formatHDOP = (value) => {
  if (value === 0) {
    return 'No Lock';
  }
  if (value <= 2) {
    return 'Excellent';
  }
  if (value <= 10) {
    return 'Good';
  }
  return 'Poor';
};

export const formatTime = (value, format, hours12) => {
  if (value) {
    const m = moment(value);
    const currentYear = moment().year();
    const dateFormat = m.year() === currentYear ? 'MMM Do' : 'MMM Do YYYY';

    switch (format) {
      case 'hour':
        return m.format(hours12 ? 'h A' : 'H');
      case 'date':
        return m.format(dateFormat); // Updated to use the new date format
      case 'time':
        return m.format(hours12 ? 'hh:mm:ss A' : 'HH:mm:ss');
      case 'minutes':
        return m.format(`${dateFormat} ${hours12 ? 'hh:mm A' : 'HH:mm'}`); // Updated to include the new date format
      default:
        return m.format(`${dateFormat} ${hours12 ? 'hh:mm:ss A' : 'HH:mm:ss'}`); // Updated to include the new date format
    }
  }
  return '';
};

export const formatStatus = (value, t) => t(prefixString('deviceStatus', value));
export const formatAlarm = (value, t) => (value ? t(prefixString('alarm', value)) : '');

export const formatCourse = (value) => {
  const courseValues = ['\u2191', '\u2197', '\u2192', '\u2198', '\u2193', '\u2199', '\u2190', '\u2196'];
  let normalizedValue = (value + 45 / 2) % 360;
  if (normalizedValue < 0) {
    normalizedValue += 360;
  }
  return courseValues[Math.floor(normalizedValue / 45)];
};

export const formatDistance = (value, unit, t) => {
  const distance = distanceFromMeters(value, unit).toFixed(0);
  return `${Number(distance).toLocaleString()} ${distanceLongString(unit, t)}`;
};

export const get30DayDistanceColor = (value, unit) => {
  const distance = distanceFromMeters(value, unit).toFixed(0);
  if (distance < 1000) {
    return 'positive';
  }
  if (distance >= 1000 && distance < 2000) {
    return '';
  }
  if (distance >= 2000 && distance < 3000) {
    return 'medium';
  }
  return 'negative';
};

export const formatAltitude = (value, unit, t) => `${altitudeFromMeters(value, unit).toFixed(2)} ${altitudeUnitString(unit, t)}`;

export const formatSpeed = (value, unit, t) => `${speedFromKnots(value, unit).toFixed(0)} ${speedUnitString(unit, t)}`;

export const formatVolume = (value, unit, t) => `${volumeFromLiters(value, unit).toFixed(2)} ${volumeUnitString(unit, t)}`;

export const formatHours = (value) => moment.duration(value).humanize();

export const formatNumericHours = (value, t) => {
  const hours = Math.floor(value / 3600000);
  const minutes = Math.floor((value % 3600000) / 60000);
  return `${hours} ${t('sharedHourAbbreviation')} ${minutes} ${t('sharedMinuteAbbreviation')}`;
};

export const formatCoordinate = (key, value, unit) => {
  let hemisphere;
  let degrees;
  let minutes;
  let seconds;

  if (key === 'latitude') {
    hemisphere = value >= 0 ? 'N' : 'S';
  } else {
    hemisphere = value >= 0 ? 'E' : 'W';
  }

  switch (unit) {
    case 'ddm':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = (value - degrees) * 60;
      return `${degrees}° ${minutes.toFixed(6)}' ${hemisphere}`;
    case 'dms':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = Math.floor((value - degrees) * 60);
      seconds = Math.round((value - degrees - minutes / 60) * 3600);
      return `${degrees}° ${minutes}' ${seconds}" ${hemisphere}`;
    default:
      return `${value.toFixed(6)}°`;
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return 'positive';
    case 'offline':
      return 'negative';
    case 'unknown':
    default:
      return 'neutral';
  }
};

export const getLastStopColor = (lastStop) => {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  if (+lastMonth > +lastStop) {
    return 'negative';
  }
  if (+oneWeekAgo > +lastStop) {
    return 'medium';
  }
  return '';
};

export const getLastUpdateColor = (lastUpdate) => {
  lastUpdate = moment(lastUpdate);
  const minusOneDay = new Date();
  minusOneDay.setDate(minusOneDay.getDate() - 1);
  if (+minusOneDay > +lastUpdate) {
    return 'negative';
  }
  return '';
};

export const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 70) {
    return 'positive';
  }
  if (batteryLevel > 30) {
    return 'medium';
  }
  return 'negative';
};

export const formatNotificationTitle = (t, notification, includeId) => {
  let title = t(prefixString('event', notification.type));
  if (notification.type === 'alarm') {
    const alarmString = notification.attributes.alarms;
    if (alarmString) {
      const alarms = alarmString.split(',');
      if (alarms.length > 1) {
        title += ` (${alarms.length})`;
      } else {
        title += ` ${formatAlarm(alarms[0], t)}`;
      }
    }
  }
  if (includeId) {
    title += ` [${notification.id}]`;
  }
  return title;
};

export const formatText = (text) => {
  // Replace bold notation (**text**) with <b>text</b>
  text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

  // Replace italic notation (*text*) with <i>text</i>
  text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');

  // Replace underline notation (__text__) with <u>text</u>
  text = text.replace(/__(.*?)__/g, '<u>$1</u>');

  // Replace strikethrough notation (~~text~~) with <s>text</s>
  text = text.replace(/~~(.*?)~~/g, '<s>$1</s>');

  return text;
};

export const formatJsonToPlainText = (jsonObject, indent = 0) => {
  const indentStr = ' '.repeat(indent);
  const entries = Object.entries(jsonObject).filter(([key]) => key !== 'latitude' && key !== 'longitude');
  return entries.map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return `${indentStr}${key}:\n${formatJsonToPlainText(value, indent + 2)}`;
    }
    return `${indentStr}${key}: ${value}`;
  }).join('\n');
};
