import { differenceInDays } from 'date-fns';
import { distanceFromMeters } from '../common/util/converter';

export const isInstallationMatching = (device, condition) => {
  if (device.disabled === true) {
    return false;
  }

  const now = new Date();
  const installTime = device.installTime ? new Date(device.installTime) : null;

  switch (condition) {
    case 'notinstalled':
      return installTime === null;
    case 'installed':
      return installTime !== null;
    case '7days':
      return installTime !== null && differenceInDays(now, installTime) <= 7;
    case '30days':
      return installTime !== null && differenceInDays(now, installTime) <= 30;
    case '60days':
      return installTime !== null && differenceInDays(now, installTime) <= 60;
    case '90days':
      return installTime !== null && differenceInDays(now, installTime) <= 90;
    default:
      return false;
  }
};

export const isLast30DaysMatching = (device, minTravel, maxTravel, distanceUnit) => {
  if (device.disabled === true) {
    return false;
  }
  if (maxTravel === 0) {
    return distanceFromMeters(device.thirtyDayDistance, distanceUnit) >= minTravel;
  }
  return distanceFromMeters(device.thirtyDayDistance, distanceUnit) >= minTravel && distanceFromMeters(device.thirtyDayDistance, distanceUnit) < maxTravel;
};

export const isConditionMatching = (device, condition) => {
  if (device.disabled === true) {
    return false;
  }
  switch (condition) {
    case 'recovery':
      return device.recovery;
    case 'abandoned':
      return device.abandonTime != null;
    case 'impounded':
      return device.impoundTime != null;
    default:
      return false;
  }
};
