import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTranslation } from './LocalizationProvider';
import { snackBarDurationLongMs } from '../util/duration';

const DialogView = ({
  item,
  open,
  handleClose,
  dialogTitle,
  content,
  validate,
  acceptTitle,
  cancelTitle,
}) => {
  const t = useTranslation();
  const [message, setMessage] = useState(false);
  if (!cancelTitle) {
    cancelTitle = t('sharedCancel');
  }

  return (
    <Dialog
      open={open}
      onClose={() => { handleClose(null); }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <DialogContentText />
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose(null)}>
          {cancelTitle}
        </Button>
        {acceptTitle && (
          <Button onClick={() => {
            if (validate && !validate()) {
              setMessage(true);
            } else {
              handleClose(item);
            }
          }}
          >
            {acceptTitle}
          </Button>
        )}
        <Snackbar
          open={message}
          autoHideDuration={snackBarDurationLongMs}
          onClose={() => setMessage(false)}
        >
          <Alert severity="error" onClose={() => setMessage(false)}>{t('errorGeneral')}</Alert>
        </Snackbar>
      </DialogActions>
    </Dialog>
  );
};

export default DialogView;
