import React from 'react';

const styles = {
  termsConditions: {
    padding: '20px',
    lineHeight: '1.6',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
  },
};

const TermsAndConditions = () => (
  <div style={styles.termsConditions}>
    <h1 style={styles.header}>Terms and Conditions</h1>
    <p>By accessing or using our website, mobile application, or other services (collectively, the “Services”), you agree to be bound by these Terms and Conditions (the “Terms”). If you do not agree to all of the terms and conditions contained in these Terms, do not use the Services.</p>
    <p>We reserve the right to change or modify these Terms at any time and in our sole discretion. If we make changes to these Terms, we will provide notice of such changes, such as by sending a notification, posting a notice on the Services, or updating the “Last Updated” date above. Your continued use of the Services after we have posted a modified version of these Terms signifies your acceptance of the modified Terms. If you do not agree to the modified Terms, you should stop using the Services.</p>

    <h2>Eligibility</h2>
    <p>The Services are intended for users who are at least 18 years old. By using the Services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into a contract.</p>

    <h2>Content and Intellectual Property</h2>
    <p>The Services and all content and materials included on the Services, including, but not limited to, text, graphics, images, logos, and software, are the property of TowerGPS.com or its licensors and are protected by copyright and trademark laws. You may not use any content or materials on the Services for any commercial purpose without the express written consent of TowerGPS.com.</p>

    <h2>User Conduct</h2>
    <p>You agree to use the Services only for lawful purposes and in accordance with these Terms. You shall not, and shall not permit any third party to:</p>
    <ul>
      <li>Use the Services to upload, post, transmit, or otherwise make available any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, deceptive, fraudulent, invasive of another’s privacy, or that violates any intellectual property or other proprietary rights of any party;</li>
      <li>Use the Services to harm minors in any way;</li>
      <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
      <li>Use the Services to interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any requirements, procedures, policies, or regulations of networks connected to the Services;</li>
      <li>Use the Services to collect or store personal data about other users;</li>
      <li>Use the Services to sell or promote any goods or services without the express written consent of TowerGPS.com;</li>
      <li>Use any data mining, robots, or similar data gathering or extraction methods in connection with the Services; or</li>
      <li>Attempt to gain unauthorized access to the Services, user accounts, or computer systems or networks connected to the Services, through hacking, password mining, or any other means.</li>
    </ul>
    <p>We reserve the right to terminate or restrict your access to the Services if, in our sole discretion, you violate any of these provisions.</p>

    <h2>Links to Third-Party Websites</h2>
    <p>The Services may contain links to third-party websites. These links are provided for your convenience only and do not constitute an endorsement by TowerGPS.com of the content or practices of such websites. TowerGPS.com is not responsible for the privacy practices or the content of such websites, and you access and use such websites at your own risk.</p>

    <h2>Disclaimer of Warranties</h2>
    <p>THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.</p>

    <h2>Limitation of Liability</h2>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL TowerGPS.com BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THE SERVICES.</p>

    <h2>Governing Law</h2>
    <p>These Terms and your use of the Services shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law.</p>

    <h2>Dispute Resolution</h2>
    <p>Any dispute arising out of or relating to these Terms or the Services shall be resolved through binding arbitration in accordance with the Commercial Arbitration Rules of the American Arbitration Association. The arbitration shall be conducted in San Francisco, California.</p>

    <h2>Miscellaneous</h2>
    <p>These Terms constitute the entire agreement between you and TowerGPS.com and govern your use of the Services, superseding any prior agreements between you and TowerGPS.com.</p>
    <p>If any provision of these Terms is found to be invalid or unenforceable, that provision shall be enforced to the maximum extent possible, and the remaining provisions shall remain in full force and effect.</p>
    <p>The failure of TowerGPS.com to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</p>
    <p>You may not assign or transfer these Terms or your rights or obligations under these Terms, in whole or in part, without the prior written consent of TowerGPS.com. TowerGPS.com may assign or transfer these Terms, in whole or in part, without restriction.</p>
    <p>The section titles in these Terms are for convenience only and have no legal or contractual effect.</p>
  </div>
);

export default TermsAndConditions;
