import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Container, FormControl, OutlinedInput, Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { useCatch } from '../reactHelper';
import { useAdministrator } from '../common/util/permissions';
import { postJsonAPI } from '../common/util/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  tokenActions: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PreferencesPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();

  const admin = useAdministrator();

  const [devices, setDevices] = useState('');

  const handleSave = useCatch(async () => {
    const deviceArray = devices.split('\n');
    deviceArray.forEach(async (device) => {
      if (device !== '') {
        const body = { name: device, uniqueId: device };
        await postJsonAPI('devices', body, true, null);
      }
    });
  // const response = await fetch(`/api/users/${user.id}`, {
  //     method: 'PUT',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ ...user }),
  //   });
  //   if (response.ok) {
  //     dispatch(sessionActions.updateUser(await response.json()));
  //     navigate(-1);
  //   } else {
  //     await handleErrorResponse(response);
  //   }
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedBulkAdd']}>
      <Container maxWidth="xs" className={classes.container}>
        {admin && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('deviceTitle')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormControl>
                  <OutlinedInput
                    multiline
                    rows={10}
                    type="text"
                    onChange={(event) => setDevices(event.target.value)}
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <div className={classes.buttons}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {t('sharedSave')}
              </Button>
            </div>
          </>
        )}
      </Container>
    </PageLayout>
  );
};

export default PreferencesPage;
