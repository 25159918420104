import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from './LocalizationProvider';
import { formatJsonToPlainText } from '../util/formatter';
import JsonPrettyPrinter from './JsonPrettyPrinter';
import { handleErrorResponse } from '../util/utils';

const AIAnalysisDialog = ({ deviceId, from, to, open, handleClose }) => {
  const t = useTranslation();
  const [textData, setTextData] = useState('');
  const [loading, setLoading] = useState(true);
  const device = useSelector((state) => state.devices.items[deviceId]);

  useEffect(() => {
    async function fetchData() {
      // Convert 'from' and 'to' from Unix timestamps (milliseconds) to ISO 8601 strings
      const fromDateISO = new Date(from).toISOString();
      const toDateISO = new Date(to).toISOString();

      const query = new URLSearchParams({
        deviceId,
        from: fromDateISO,
        to: toDateISO,
      }).toString();

      try {
        const response = await fetch(`/api/reports/topstopanalysis?${query}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          const jsonData = await response.json();
          const parsedJson = JSON.parse(jsonData.data);
          setTextData(parsedJson);
          setLoading(false);
        } else {
          await handleErrorResponse(response);
        }
      } finally {
        setLoading(false);
      }
    }

    if (open) {
      fetchData();
    }
  }, [deviceId, from, to, open]);

  const handleCopyToClipboard = () => {
    const plainText = textData.map((item) => formatJsonToPlainText(item)).join('\n\n');
    navigator.clipboard.writeText(plainText);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          width: '60%',
          maxWidth: '80%',
          height: '70%',
        },
      }}
    >
      <DialogTitle>
        {`${t('reportAIAnalysis')}: ${device.name}`}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', padding: '16px', height: '100%' }}>
        <Box style={{ flex: 1, overflowY: 'auto', paddingRight: '16px' }}>
          {loading ? (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
              <Typography style={{ marginTop: '16px' }}>{t('reportAIAnalyzingData')}</Typography>
            </Box>
          ) : (
            textData ? (
              <Box position="relative">
                <Tooltip title={t('sharedCopyToClipboard')}>
                  <IconButton
                    onClick={handleCopyToClipboard}
                    style={{ position: 'absolute', right: 0, top: 0 }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', padding: '16px', overflowX: 'auto', fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>
                  <JsonPrettyPrinter data={textData} />
                </div>
              </Box>
            ) : (
              <Typography>{t('sharedNoData')}</Typography>
            )
          )}
        </Box>
        <Box style={{ paddingTop: '1em' }}>
          <Typography variant="body2" align="center">
            {t('sharedAIGeneratedDisclaimer')}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AIAnalysisDialog;
