import { useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import { map } from './core/DialogMapView';

const DialogMapCamera = ({ latitude, longitude, positions, zoom = 14 }) => {
  useEffect(() => {
    if (positions && positions.length > 0) {
      const coordinates = positions.map((item) => [item.longitude, item.latitude]);

      if (coordinates.length > 0) {
        // Calculate the bounds of the coordinates
        const bounds = coordinates.reduce(
          (bounds, coord) => bounds.extend(coord),
          new maplibregl.LngLatBounds(coordinates[0], coordinates[0]),
        );

        // Expand the bounds by 25  meters
        const expandByMeters = 25;
        const earthRadius = 6371000; // Earth's radius in meters

        const expandByDegreesLat = (expandByMeters / earthRadius) * (180 / Math.PI);
        const expandByDegreesLng = (expandByMeters / (earthRadius * Math.cos((Math.PI * coordinates[0][1]) / 180))) * (180 / Math.PI);

        const expandedBounds = bounds.extend([
          bounds.getNorthEast().lng + expandByDegreesLng,
          bounds.getNorthEast().lat + expandByDegreesLat,
        ]).extend([
          bounds.getSouthWest().lng - expandByDegreesLng,
          bounds.getSouthWest().lat - expandByDegreesLat,
        ]);

        // Fit the map to the expanded bounds
        const canvas = map.getCanvas();
        map.fitBounds(expandedBounds, {
          padding: Math.min(canvas.width, canvas.height) * 0.1,
          duration: 0,
        });
      }
    } else {
      map.easeTo({
        center: [longitude, latitude],
        zoom: Math.max(map.getZoom(), zoom),
      });
    }
  }, [latitude, longitude, positions]);

  return null;
};

export default DialogMapCamera;
