import React, { useState } from 'react';
import {
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import DialogMapView from '../../map/core/DialogMapView';
import DialogMapPositions from '../../map/DialogMapPositions';
import DialogMapCamera from '../../map/DialogMapCamera';
import DialogMapGeofence from '../../map/MapGeofence';
import JsonPrettyPrinter from '../../common/components/JsonPrettyPrinter';
import { handleErrorResponse } from '../../common/util/utils';

const StopPredictionsDialog = ({ longitude, latitude, deviceId, from, to, numStops, disabled }) => {
  const t = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textData, setTextData] = useState('');

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClickOpen = async () => {
    if (disabled || numStops < 3) return; // Prevent action if disabled

    setLoading(true);
    const query = new URLSearchParams({
      longitude,
      latitude,
      deviceId,
      from,
      to,
    }).toString();
    try {
      const response = await fetch(`/api/reports/stoppredictions?${query}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const jsonData = await response.json();
        const parsedJson = JSON.parse(jsonData.data);
        setTextData(parsedJson);
        setOpen(true); // Open the dialog after data is fetched
      } else {
        await handleErrorResponse(response);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={t('reportAIPredictions')}>
        <span>
          <IconButton onClick={handleClickOpen} disabled={disabled || numStops < 3}>
            <InsightsIcon />
          </IconButton>
        </span>
      </Tooltip>
      {loading && <CircularProgress size={24} style={{ marginLeft: 8 }} />}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: '60%',
            maxWidth: '60%',
          },
        }}
      >
        <DialogTitle>
          {t('reportAIPredictions')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', padding: '16px' }}>
          <Box style={{ flex: 6, paddingRight: '16px', position: 'relative' }}>
            {loading ? (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
                <Typography style={{ marginTop: '16px' }}>{t('reportAIAnalyzingData')}</Typography>
              </Box>
            ) : (
              textData ? (
                <Box position="relative">
                  <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', padding: '16px', overflowX: 'auto', fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>
                    <JsonPrettyPrinter data={textData} />
                  </div>
                </Box>
              ) : (
                <Typography>{t('sharedNoData')}</Typography>
              )
            )}
            <Typography variant="body2" align="center" style={{ marginTop: '1em' }}>
              {t('sharedAIGeneratedDisclaimer')}
            </Typography>
          </Box>
          {largeScreen && (
            <Box style={{ flex: 4 }}>
              <DialogMapView>
                <DialogMapGeofence />
                <DialogMapPositions positions={[{ longitude, latitude, deviceId }]} />
                <DialogMapCamera positions={[{ longitude, latitude }]} />
              </DialogMapView>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StopPredictionsDialog;
