import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    server: null,
    user: null,
    socket: null,
    positions: {},
    positionsBuffer: [],
    history: {},
  },
  reducers: {
    updateServer(state, action) {
      state.server = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateSocket(state, action) {
      state.socket = action.payload;
    },
    updatePositions(state, action) {
      action.payload.forEach((position) => {
        state.positionsBuffer.push(position);
      });
    },
    flushBufferToPositions(state) {
      const newPositions = { ...state.positions };
      state.positionsBuffer.forEach((position) => {
        newPositions[position.deviceId] = position;
        state.positions[position.deviceId] = position;
      });
      state.positions = { ...state.positions, ...newPositions }; // Merge and replace positions all at once
      state.positionsBuffer = []; // Clear the buffer after flushing
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
