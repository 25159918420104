import msgpack from 'msgpack-lite';

const procesData = async (response, json, message, setActionResult) => {
  if (response.ok) {
    if (message != null) {
      setActionResult(message);
    }
    if (json) {
      const data = await response.json();
      return data;
    }
    const data = await response.text();
    return data;
  }
  setActionResult(await response.text());
  return null;
};

export const callJsonAPI = async (endpoint, method, item, json, message, setActionResult) => {
  if (item) {
    const response = await fetch(`/api/${endpoint}`, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });
    return procesData(response, json, message, setActionResult);
  }
  const response = await fetch(`/api/${endpoint}`, {
    method,
    headers: { 'Content-Type': 'application/json' },
  });
  return procesData(response, json, message, setActionResult);
};

export const postJsonAPI = async (endpoint, item, json, message, setActionResult) => {
  const method = 'POST';
  return callJsonAPI(endpoint, method, item, json, message, setActionResult);
};

export const putJsonAPI = async (endpoint, item, json, message, setActionResult) => {
  const method = 'PUT';
  return callJsonAPI(endpoint, method, item, json, message, setActionResult);
};

export const deleteJsonAPI = async (endpoint, item, json, message, setActionResult) => {
  const method = 'DELETE';
  return callJsonAPI(endpoint, method, item, json, message, setActionResult);
};

export const postURLSearchParamsAPI = async (endpoint, item, json, message, setActionResult) => {
  const response = await fetch(`/api/${endpoint}`, {
    method: 'POST',
    body: new URLSearchParams(item),
  });
  return procesData(response, json, message, setActionResult);
};

export const getURLSearchParamsAPI = async (endpoint, json, message, setActionResult) => {
  const response = await fetch(`/api/${endpoint}`);
  return procesData(response, json, message, setActionResult);
};

export const handleErrorResponse = async (response) => {
  if (response.status === 401) {
    // Display the error message for 5 seconds before redirecting
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
    const errorMessage = 'Access Denied: Please Login.';
    throw new Error(errorMessage);
  }

  const errorText = await response.text();

  let errorData;
  try {
    errorData = JSON.parse(errorText);
  } catch {
    throw new Error(response.statusText); // Fallback if the response isn't JSON
  }

  // If JSON parsing succeeds, throw the specific 'error' part
  throw new Error(errorData.error || response.statusText); // Fallback if 'error' field is missing
};

/**
 * Enhanced fetch function that automatically handles GZIP-encoded responses and decoding.
 *
 * @param {string} url - The URL to fetch data from.
 * @param {Object} options - Additional fetch options (e.g., method, headers).
 * @param {Function} decoder - Function to decode the response (e.g., msgpack.decode, JSON.parse).
 * @param {string} accept - The value of the Accept header (e.g., 'application/msgpack').
 * @returns {Promise<Object>} - A promise that resolves to the decoded data.
 * @throws {Error} - Throws an error if the fetch or decoding fails.
 */
export async function fetchAndDecode(url, options = {}, decoder = JSON.parse, accept = 'application/json') {
  const response = await fetch(url, {
    ...options,
    headers: {
      Accept: accept, // Set the Accept header
      ...options.headers, // Merge with any additional headers provided
    },
  });

  // Check if the response is ok (status code 2xx)
  if (!response.ok) {
    await handleErrorResponse(response);
  }

  const buffer = await response.arrayBuffer(); // Get the raw binary data

  let data;
  if (accept === 'application/json') {
    // Decode the binary data into a string and then parse as JSON
    const text = new TextDecoder().decode(buffer);
    data = decoder(text); // JSON.parse
  } else {
    // Decode the binary data directly as MessagePack
    data = decoder(new Uint8Array(buffer)); // msgpack.decode
  }

  return data;
}

/**
 * Fetches data from a given URL and decodes the MessagePack-encoded response.
 *
 * @param {string} url - The URL to fetch data from.
 * @param {Object} options - Additional fetch options, if needed (optional).
 * @returns {Promise<Object>} - A promise that resolves to the decoded data as a JavaScript object.
 * @throws {Error} - Throws an error if the fetch fails or the decoding fails.
 */
export async function fetchMsgPack(url, options = {}) {
  return fetchAndDecode(url, options, msgpack.decode, 'application/msgpack');
}
