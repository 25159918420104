import React, { useState } from 'react';
import {
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  Tooltip,
} from '@mui/material';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import HeatMap from './HeatMap';
import { handleErrorResponse } from '../../common/util/utils';

const calculateTotalTimeSlots = (from, to) => {
  const timeSlotCount = {};
  let currentTime = new Date(from);

  while (currentTime <= new Date(to)) {
    const hour = currentTime.getHours();
    const day = currentTime.getDay();
    const slotKey = `${day}-${hour}`;

    if (timeSlotCount[slotKey]) {
      timeSlotCount[slotKey] += 1;
    } else {
      timeSlotCount[slotKey] = 1;
    }

    currentTime = new Date(currentTime.setHours(currentTime.getHours() + 1));
  }

  return timeSlotCount;
};

const transformData = (data, from, to) => {
  const heatmapData = [];
  const timeSlotCount = calculateTotalTimeSlots(from, to);

  data.forEach((item) => {
    const startTime = new Date(item.startTime);
    const endTime = new Date(item.endTime);
    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      const hour = currentTime.getHours();
      const day = currentTime.getDay();
      let nextHour = new Date(currentTime);
      nextHour = new Date(nextHour.setHours(nextHour.getHours() + 1));

      // Check if the stop duration in the current time slot exceeds 30 minutes
      const durationInSlot = Math.min(endTime, nextHour) - Math.max(startTime, currentTime);
      const durationInMinutes = durationInSlot / (1000 * 60);

      if (durationInMinutes > 30) {
        const existingSlot = heatmapData.find((slot) => slot.hour === hour && slot.day === day);
        if (existingSlot) {
          existingSlot.value += 1;
        } else {
          heatmapData.push({ hour, day, value: 1 });
        }
      }

      // Create a new Date object for the next hour to avoid modifying the const variable
      currentTime = new Date(currentTime.setHours(currentTime.getHours() + 1));
    }
  });

  return [heatmapData, timeSlotCount];
};

const StopsPatternDialog = ({ longitude, latitude, deviceId, from, to, title, disabled }) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [heatMapData, setHeatMapData] = useState([]);
  const [timeSlotCount, setTimeSlotCount] = useState({});

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClickOpen = async () => {
    if (disabled) return; // Prevent action if disabled

    setLoading(true);
    const query = new URLSearchParams({
      longitude,
      latitude,
      deviceId,
      from,
      to,
    }).toString();
    try {
      const response = await fetch(`/api/reports/locationstops?${query}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        const [transformedData, timeSlotCount] = transformData(data, from, to);
        setHeatMapData(transformedData);
        setTimeSlotCount(timeSlotCount);
        setOpen(true); // Open the dialog after data is fetched
      } else {
        await handleErrorResponse(response);
      }
    } catch (error) {
      await handleErrorResponse(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={t('reportPatterns')}>
        <span>
          <IconButton onClick={handleClickOpen} disabled={disabled}>
            <CalendarViewMonthIcon />
          </IconButton>
        </span>
      </Tooltip>
      {loading && <CircularProgress size={24} style={{ marginLeft: 8 }} />}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: '60%',
            maxWidth: '60%',
          },
        }}
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!largeScreen && (
            <Box sx={{ height: '75vh', width: '100%', overflowX: 'auto' }}>
              {/* Set a fixed width for the inner box */}
              <Box sx={{ width: '1000px' }}>
                <div style={{ height: '400px', width: '100%' }}>
                  <HeatMap data={heatMapData} timeSlotCount={timeSlotCount} />
                </div>
              </Box>
            </Box>
          )}
          {largeScreen && (
            <div style={{ height: '400px', width: '100%' }}>
              <HeatMap data={heatMapData} timeSlotCount={timeSlotCount} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StopsPatternDialog;
