import React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useReportStyles from '../common/useReportStyles';

const ActionPanel = ({ showFilterMenu, onToggleFilter, icons }) => {
  const classes = useReportStyles();

  return (
    <div className={classes.actionPanel}>
      {showFilterMenu && (
        <IconButton onClick={onToggleFilter} color="default">
          <MenuIcon />
        </IconButton>
      )}
      {icons}
    </div>
  );
};

export default ActionPanel;
