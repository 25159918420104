import React, { useState } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import { useGlobalStyles } from '../util/classes';

const SettingsDialog = ({ open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();
  const [item, setItem] = useState();

  const validate = () => item && item.name;

  if (!item) {
    setItem({ name: '' });
  }

  return (
    <DialogView
      item={item}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('deviceCommand')}
      validate={validate}
      acceptTitle={t('commandSend')}
      content={item && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>
                <TextField
                  value={item.name || ''}
                  label={t('sharedName')}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    />
  );
};

export default SettingsDialog;
