import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  Toolbar, IconButton, OutlinedInput, InputAdornment, Popover, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, Badge, ListItemButton, ListItemText, Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';
import TuneIcon from '@mui/icons-material/Tune';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAdministrator } from '../common/util/permissions';
import { distanceLongString } from '../common/util/converter';
import { isConditionMatching, isLast30DaysMatching, isInstallationMatching } from './filterUtil';
import DeviceRow from './DeviceRow';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
}));

const MainToolbar = ({
  filteredDevices,
  devicesOpen,
  setDevicesOpen,
  keyword,
  setKeyword,
  filter,
  setFilter,
  showTerminated,
  setShowTerminated,
  distanceUnit,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslation();

  const admin = useAdministrator();

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);

  const toolbarRef = useRef();
  const inputRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [devicesAnchorEl, setDevicesAnchorEl] = useState(null);

  const deviceTerminatedCount = () => Object.values(devices).filter((d) => d.disabled === true).length;
  const deviceStatusCount = (status) => (showTerminated ? 0 : Object.values(devices).filter((d) => d.disabled === false && d.status === status).length);
  const deviceConditionCount = (condition) => (showTerminated ? 0 : Object.values(devices).filter((d) => isConditionMatching(d, condition)).length);
  const deviceInstallationCount = (condition) => (showTerminated ? 0 : Object.values(devices).filter((d) => isInstallationMatching(d, condition)).length); // handle installation counts
  const deviceLast30DaysCount = (minTravel, maxTravel) => (showTerminated ? 0 : Object.values(devices).filter((d) => isLast30DaysMatching(d, minTravel, maxTravel, distanceUnit)).length);

  return (
    <Toolbar ref={toolbarRef} className={classes.toolbar}>
      <IconButton edge="start" onClick={() => setDevicesOpen(!devicesOpen)}>
        {devicesOpen ? <MapIcon /> : <ViewListIcon />}
      </IconButton>
      <OutlinedInput
        ref={inputRef}
        placeholder={t('sharedSearchDevices')}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onFocus={() => setDevicesAnchorEl(toolbarRef.current)}
        onBlur={() => setDevicesAnchorEl(null)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton size="small" edge="end" onClick={() => setFilterAnchorEl(inputRef.current)}>
              <Badge
                color="info"
                variant="dot"
                invisible={
                  !filter.statuses.length &&
                  !filter.groups.length &&
                  (!filter.conditions || !filter.conditions.length) &&
                  (!filter.last30DaysTravel || !filter.last30DaysTravel.length) &&
                  (!filter.installation || !filter.installation.length)
                }
              >
                <TuneIcon fontSize="small" />
              </Badge>
            </IconButton>
          </InputAdornment>
        )}
        size="small"
        fullWidth
      />
      <Popover
        open={!!devicesAnchorEl && !devicesOpen}
        anchorEl={devicesAnchorEl}
        onClose={() => setDevicesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: Number(theme.spacing(2).slice(0, -2)),
        }}
        marginThreshold={0}
        PaperProps={{
          style: { width: `calc(${toolbarRef.current?.clientWidth}px - ${theme.spacing(4)})` },
        }}
        elevation={1}
        disableAutoFocus
        disableEnforceFocus
      >
        {filteredDevices.slice(0, 3).map((_, index) => (
          <DeviceRow key={filteredDevices[index].id} data={filteredDevices} index={index} />
        ))}
        {filteredDevices.length > 3 && (
          <ListItemButton alignItems="center" onClick={() => setDevicesOpen(true)}>
            <ListItemText
              primary={t('notificationAlways')}
              style={{ textAlign: 'center' }}
            />
          </ListItemButton>
        )}
      </Popover>
      <Popover
        open={!!filterAnchorEl}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.filterPanel}>
          <FormControl>
            <InputLabel>{t('deviceStatus')}</InputLabel>
            <Select
              label={t('deviceStatus')}
              value={filter.statuses}
              onChange={(e) => setFilter({ ...filter, statuses: e.target.value })}
              multiple
            >
              <MenuItem value="online">{`${t('deviceStatusOnline')} (${deviceStatusCount('online')})`}</MenuItem>
              <MenuItem value="offline">{`${t('deviceStatusOffline')} (${deviceStatusCount('offline')})`}</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>{t('deviceCondition')}</InputLabel>
            <Select
              label={t('deviceCondition')}
              value={filter.conditions ? filter.conditions : []}
              onChange={(e) => setFilter({ ...filter, conditions: e.target.value })}
              multiple
            >
              <MenuItem value="abandoned">{`${t('deviceConditionAbandoned')} (${deviceConditionCount('abandoned')})`}</MenuItem>
              <MenuItem value="recovery">{`${t('deviceConditionRecovery')} (${deviceConditionCount('recovery')})`}</MenuItem>
              <MenuItem value="impounded">{`${t('deviceConditionImpounded')} (${deviceConditionCount('impounded')})`}</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>{t('deviceInstallation')}</InputLabel>
            <Select
              label={t('deviceInstallation')}
              value={filter.installation ? filter.installation : []}
              onChange={(e) => setFilter({ ...filter, installation: e.target.value })}
              multiple
            >
              <MenuItem value="notinstalled">{`${t('deviceInstallationNotInstalled')} (${deviceInstallationCount('notinstalled')})`}</MenuItem>
              <MenuItem value="installed">{`${t('deviceInstallationInstalled')} (${deviceInstallationCount('installed')})`}</MenuItem>
              <MenuItem value="7days">{`${t('deviceInstallationLast7days')} (${deviceInstallationCount('7days')})`}</MenuItem>
              <MenuItem value="30days">{`${t('deviceInstallationLast30days')} (${deviceInstallationCount('30days')})`}</MenuItem>
              <MenuItem value="60days">{`${t('deviceInstallationLast60days')} (${deviceInstallationCount('60days')})`}</MenuItem>
              <MenuItem value="90days">{`${t('deviceInstallationLast90days')} (${deviceInstallationCount('90days')})`}</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>{t('deviceLast30Days')}</InputLabel>
            <Select
              label={t('deviceLast30Days')}
              value={filter.last30DaysTravel ? filter.last30DaysTravel : []}
              onChange={(e) => setFilter({ ...filter, last30DaysTravel: e.target.value })}
              multiple
            >
              <MenuItem value="2000">{`${t('deviceLast30Days2k-3k')} ${distanceLongString(distanceUnit, t)} (${deviceLast30DaysCount(2000, 3000)})`}</MenuItem>
              <MenuItem value="3000">{`${t('deviceLast30Days3k-4k')} ${distanceLongString(distanceUnit, t)} (${deviceLast30DaysCount(3000, 4000)})`}</MenuItem>
              <MenuItem value="4000">{`${t('deviceLast30Days4k-5k')} ${distanceLongString(distanceUnit, t)} (${deviceLast30DaysCount(4000, 5000)})`}</MenuItem>
              <MenuItem value="5000">{`${t('deviceLast30Days5k+')} ${distanceLongString(distanceUnit, t)} (${deviceLast30DaysCount(5000, 0)})`}</MenuItem>
            </Select>
          </FormControl>
          {admin && (
            <FormControl>
              <InputLabel>{t('settingsGroups')}</InputLabel>
              <Select
                label={t('settingsGroups')}
                value={filter.groups}
                onChange={(e) => setFilter({ ...filter, groups: e.target.value })}
                multiple
              >
                {Object.values(groups).sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                  <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={showTerminated} onChange={(e) => setShowTerminated(e.target.checked)} />}
              label={`${t('sharedTerminated')} (${deviceTerminatedCount()})`}
            />
          </FormGroup>
        </div>
      </Popover>
      {filteredDevices.length > 0 && (
        <Typography variant="body1" component="div" style={{ margin: '0 10px' }}>
          {filteredDevices.length}
        </Typography>
      )}
    </Toolbar>
  );
};

export default MainToolbar;
