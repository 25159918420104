import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DialogView from './DialogView';
import { useTranslation } from './LocalizationProvider';
import { useGlobalStyles } from '../util/classes';
import { fetchMsgPack } from '../util/utils';

const RecoveryDialog = ({ deviceId, open, handleClose }) => {
  const t = useTranslation();
  const classes = useGlobalStyles();

  const [users, setUsers] = useState();

  const validate = () => true;

  const setAssignment = async (users) => {
    const results = [];
    users.forEach((user) => {
      results.push(fetchMsgPack(`/api/devices?userId=${user.id}`)
        .then((devices) => {
          devices.forEach((device) => {
            if (device.id === deviceId) {
              user.origAssigned = true;
              user.newAssigned = true;
            }
          });
        }));
    });
    await Promise.all(results);
    setUsers(users);
  };

  useEffect(() => {
    async function fetchData() {
      fetch('/api/users?recovery=1')
        .then((response) => response.json())
        .then((users) => {
          setAssignment(users);
        });
    }
    fetchData();
  }, [deviceId]);

  const handleOnChange = (index) => {
    users[index].newAssigned = !users[index].newAssigned;
    const newUsers = [...users];
    setUsers(newUsers);
  };

  return (
    <DialogView
      item={users}
      open={open}
      handleClose={handleClose}
      dialogTitle={t('sharedRecovery')}
      validate={validate}
      acceptTitle={t('sharedAssign')}
      content={(
        <Table size="small">
          <TableBody>
            {!users && (
              <TableRow>
                <TableCell className={classes.cell} align="center">
                  {t('sharedLoading')}
                </TableCell>
              </TableRow>
            )}
            {users && users.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell className={classes.cell}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={user.newAssigned} onChange={() => handleOnChange(index)} />}
                      label={user.name}
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    />
  );
};

export default RecoveryDialog;
