import React from 'react';
import {
  Typography,
  Link,
  BottomNavigationAction,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.colors.primary,
    justifyContent: 'space-between',
  },
  secondary: {
    color: theme.palette.colors.secondary,
    justifyContent: 'space-between',
  },
  negative: {
    color: theme.palette.colors.negative,
    justifyContent: 'space-between',
  },
  disabled: {
    color: theme.palette.colors.disabled,
    justifyContent: 'space-between',
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

export const ActionIcon = ({ text, color, action, icon, disabled, largeScreen }) => {
  const classes = useStyles();

  const actionClass = disabled === true ? classes.disabled :
    color === 'primary' ? classes.primary :
      color === 'secondary' ? classes.secondary :
        color === 'negative' ? classes.negative : classes.actions;

  if (!largeScreen) {
    return (
      <BottomNavigationAction
        className={actionClass}
        showLabel={largeScreen}
        icon={icon}
        onClick={action}
        disabled={disabled}
        sx={{ minWidth: '70px' }}
      />
    );
  }
  return (
    <BottomNavigationAction
      className={actionClass}
      showLabel={largeScreen}
      label={text}
      icon={icon}
      onClick={action}
      disabled={disabled}
    />
  );
};

export const ActionLink = ({ text, link }) => {
  const classes = useStyles();

  return (
    <Link href={link} underline="hover" target="_blank" rel="noopener" className={classes.actions}>
      <Typography variant="body2">{text}</Typography>
    </Link>
  );
};

export const ActionMenuItem = ({ text, action, icon }) => {
  (
    <MenuItem onClick={action}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};
