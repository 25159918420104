import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as Logo } from '../resources/images/logo.svg';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '240px',
    maxHeight: '240px',
    width: 'auto',
    height: 'auto',
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  return (
    <Tooltip title="TowerGPS.com">
      <IconButton className={classes.disableRipple} onClick={() => window.open('https://www.towergps.com')}>
        <Logo className={classes.image} style={{ color }} />
      </IconButton>
    </Tooltip>
  );
};

export default LogoImage;
